.content {
  .container {
    @include outer-container;
    max-width: 1270px;
  }
  .container-slider {
    @include outer-container;
    max-width: 1600px;
  }
  .thumbnail-menu {
    padding: 0;
    margin: 0;
    padding-bottom: 25px;
    @include media(min-width 320px max-width 720px, 12) {
      display: none;
    }
    .slick-slider-item {
      float: left;
      width: 25%;
      text-align: center;
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      cursor: pointer;
      outline: none;
      @include media(min-width 320px max-width 720px, 12) {
        margin: 20px 0;
      }
      p {
        font-size: 21px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-style: italic;
        margin: 0;
        @include media(min-width 320px max-width 740px, 12) {
          font-size: 20px;
        }
      }
      img {
        margin: auto;
        max-width: 190px;
        width: 100%;
      }
    }
    .slick-list {
      overflow: initial;
    }
    .slick-current {
      p {
        color: #212121;
      }
    }
    .slick-current:after {
      width: 0;
      height: 0;
      content: "";
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid white;
      display: block;
      margin: auto;
      position: relative;
      top: 45px;
      z-index: 9999;
      margin-top: -20px;
      @include media(min-width 320px max-width 720px, 12) {
        display: none;
      }
    }
  }
  .slider {
    -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    margin-bottom: 65px !important;
    .container {
      max-width: 1270px;
      @include media(min-width 961px max-width 1400px, 12) {
        padding: 50px;
        padding-top: 50px;
      }
    }
    .slick-prev,
    .slick-next {
      top: 56%;
    }
    .slick-slide {
      outline: none;
    }
    .slick-dots {
      padding-bottom: 35px;
      bottom: -60px;
    }
    .slick-next {
      right: 55px;
      background: url('../img/slider-next.png');
      height: 34px;
      @include media(min-width 320px max-width 960px,
              12) {
        top: 50px;
        right: 10px;
      }
      @include media(min-width 961px max-width 1400px,
              12) {
        right: 10px;
      }
    }
    .slick-next:before {
      content: '';
    }
    .slick-prev {
      left: 55px;
      background: url('../img/slider-previous.png');
      height: 34px;
      position: absolute;
      z-index: 9999;
      @include media(min-width 320px max-width 960px,
              12) {
        top: 50px;
        left: 10px;
      }
      @include media(min-width 961px max-width 1400px,
              12) {
        left: 10px;
      }
    }
    .slick-prev:before {
      content: '';
    }
    .slick-dots li button:before {
      font-size: 20px;
      opacity: 1;
      color: #263238;
    }
    .slick-dots li.slick-active button:before {
      font-size: 30px;
      opacity: 1;
      color: $bluegreen;
    }
    .logo {
      position: absolute;
      top: 60px;
      -webkit-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
      width: 130px;
      height: 130px;
      @include media(min-width 320px max-width 960px,
              12) {
        position: static;
        margin: auto;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        img {
          margin: auto;
          width: 100%;
        }
      }
      @include media(min-width 961px max-width 1580px,
              12) {
        position: static;
        margin: auto;
        width: 130px;
        margin-top: 50px;
        img {
          margin: auto;
        }
      }
      img {
        width: 100%;
      }
    }
    .left,
    .right {
      width: 50%;
      float: left;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
        padding: 10px;
      }
    }
    .left {
      h2 {
        font-size: 72px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-style: italic;
        margin-top: 60px;
        margin-bottom: 0;
        width: 120%;
        @include media(min-width 320px max-width 960px,
                12) {
          text-align: center;
          width: 100%;
        }
      }
      .data {
        float: left;
        margin-top: 10px;
        p {
          float: left;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 21px;
          margin: 0;
          line-height: 30px;
          span {
            font-weight: 500;
          }
        }
        p:after {
          content: "|";
          margin: 0 12px;
          @include media(min-width 320px max-width 960px,
                  12) {
            content: "";
          }
        }
        p:nth-of-type(4):after,
        p:nth-of-type(5):after {
          content: "";
        }
        span.green {
          color: $bluegreen;
        }
        .more-data {
          span {
            color: $bluegreen;
          }
          a {
            color: $bluegreen;
            font-weight: 500;
            text-decoration: none;
            img {
              display: inline-block;
              position: relative;
              top: 1px;
              left: 5px;
            }
          }
        }
      }
      .advantages {
        float: left;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        h3 {
          font-weight: 500;
          font-size: 21px;
          margin-top: 35px;
          margin-bottom: 10px;
        }
        .single {
          width: 50%;
          height: 50px;
          float: left;
          margin: 10px 0;
          padding-right: 65px;
          @include media(min-width 320px max-width 960px,
                  12) {
            width: 100%;
            padding: 0;
          }
          @include media(min-width 961px max-width 1400px,
                  12) {
            padding-right: 10px;
          }
          .icon {
            float: left;
            margin-right: 18px;
            width: 30px;
            height: 50px;
            img {
              margin: auto;
            }
          }
          p {
            margin: 0;
            font-weight: 300;
            font-size: 18px;
            @include media(min-width 961px max-width 1400px,
                    12) {
              font-size: 14px;
            }
          }
        }
      }
      .bottom-bar {
        max-width: 520px;
        width: 100%;
        float: left;
        margin-top: 45px;
        @include media(min-width 320px max-width 960px,
                12) {
          max-width: 100%;
        }
        .price {
          float: left;
          background: #26a69a;
          color: white;
          width: 130px;
          height: 70px;
          text-align: center;
          @include media(min-width 320px max-width 960px,
                  12) {
            float: none;
            margin: auto;
            padding-top: 5px;
            margin-bottom: 10px;
          }
          h4 {
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            font-size: 13px;
            margin-top: 10px;
            span {
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
              font-size: 24px;
              display: block;
              margin-top: 5px;
            }
          }
        }
        .availability {
          float: left;
          background: $bluegreen;
          max-width: 390px;
          width: 100%;
          transition: .4s;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          @include media(min-width 320px max-width 960px,
                  12) {
            float: none;
            margin: auto;
          }
          @include media(min-width 961px max-width 1400px,
                  12) {
            max-width: 300px;
          }
          a {
            border: none;
            background: none;
            width: 100%;
            height: 70px;
            display: block;
            line-height: 70px;
            text-align: center;
            color: white;
            text-decoration: none;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 24px;
          }
        }
        .availability:hover {
          background: $bluegreenlight;
        }
        p.note {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 13px;
          margin-top: 10px;
          float: left;
        }
      }
    }
    .right {
      .gallery {
        float: right;
        margin-top: 80px;
        @include media(min-width 320px max-width 960px,
                12) {
          margin-top: 20px;
        }
        img {
          display: inline-block;
          width: 70px;
          height: 70px;
          object-fit: contain;
        }
      }
      .main-photo {
        float: right;
        max-width: 638px;
        width: 100%;
        img {
          width: 100%;
        }
        a {
          outline: none;
        }
        @include media(min-width 320px max-width 1400px,
                12) {
          float: none;
          margin: auto;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .triangle-up-right {
    width: 0;
    height: 0;
    padding-left: 100%;
    padding-top: 50px;
    overflow: hidden;
    position: relative;
    top: 0px;
    margin-bottom: -50px;
    @include media(min-width 320px max-width 960px,
            12) {
      display: none;
    }
  }
  .triangle-up-right:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left: -2000px;
    margin-top: -50px;
    border-left: 2000px solid transparent;
    border-top: 15px solid white;
  }
  .triangle-up-right-2 {
    width: 0;
    height: 0;
    padding-right: 100%;
    padding-top: 50px;
    overflow: hidden;
    position: relative;
    top: -50px;
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari */
    transform: rotate(180deg);
    margin-bottom: -50px;
    @include media(min-width 320px max-width 960px,
            12) {
      display: none;
    }
  }
  .triangle-up-right-2:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-right: -2000px;
    margin-top: -50px;
    border-right: 2000px solid transparent;
    border-top: 15px solid white;
  }
  .how-we-work {
    height: 550px;
    float: left;
    width: 100%;
    background: url('../img/how-we-work.png');
    background-size: cover;
    background-position: center;
    font-style: italic;
    @include media(min-width 320px max-width 1400px,
            12) {
      height: auto;
      padding: 10px;
    }
    h2 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-size: 72px;
      color: $bluegreen;
      margin: 0;
      margin-top: 100px;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 40px;
        text-align: center;
      }
      @include media(min-width 721px max-width 960px,
              12) {
        text-align: center;
      }
    }
    .col {
      float: left;
      width: 33.3333%;
      padding-right: 110px;
      @include media(min-width 320px max-width 720px,
              12) {
        width: 100%;
        padding: 0;
      }
      @include media(min-width 721px max-width 960px,
              12) {
        padding: 10px;
      }
      h3 {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 48px;
        color: $bluegreen;
        margin: 0;
        margin-top: 30px;
      }
      h4 {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 24px;
        color: $bluegreen;
        margin: 0;
        margin-top: 8px;
      }
      p {
        font-style: initial;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
  .boxes {
    background: #eceff1;
    -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    padding-top: 95px;
    float: left;
    width: 100%;
    @include media(min-width 320px max-width 1400px,
            12) {
      padding: 10px;
      padding-top: 50px;
    }
    .left,
    .center,
    .right {
      @include span-columns(4);
      @include media(min-width 320px max-width 720px,
              12) {
        @include span-columns(12);
        margin: 0;
      }
    }
    .left {
      @include media(min-width 320px max-width 720px,
              12) {
        margin-bottom: 50px;
      }
      h3 {
        margin: 0;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        color: $bluegreen;
        font-size: 72px;
        line-height: 72px;
        @include media(min-width 320px max-width 720px,
                12) {
          font-size: 50px;
          line-height: 50px;
        }
        @include media(min-width 721px max-width 960px,
                12) {
          font-size: 50px;
          line-height: 50px;
        }
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 21px;
        padding-right: 50px;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
    .center {
      .events,
      .advices {
        width: 100%;
        height: 335px;
        padding-top: 190px;
        color: white;
        padding-left: 28px;
        -webkit-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
        @include media(min-width 320px max-width 720px,
                12) {
          padding-top: 150px;
        }
        @include media(min-width 721px max-width 960px,
                12) {
          padding-top: 100px;
        }
        h4 {
          margin: 0;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-style: italic;
          font-size: 36px;
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 18px;
          float: left;
          margin-top: 5px;
        }
        a {
          float: right;
          background-image: url('../img/button-right.png');
          width: 22px;
          height: 22px;
          margin-right: 28px;
          margin-top: 5px;
        }
      }
      .events {
        background-image: url('../img/events.png');
        background-size: 100%;
        margin-bottom: 30px;
        transition: all .3s ease-in-out;
        background-size: 100%;
        @include media(min-width 320px max-width 1400px,
                12) {
          background-size: cover;
        }
      }
      .events:hover {
        background-size: 110%;
        @include media(min-width 320px max-width 1400px,
                12) {
          background-size: cover;
        }
      }
      .advices {
        background: url('../img/advices.png');
        background-size: 100%;
        margin-bottom: 30px;
        transition: all .3s ease-in-out;
        background-size: 100%;
        margin-bottom: 108px;
        @include media(min-width 320px max-width 1400px,
                12) {
          background-size: cover;
          margin-bottom: 30px;
        }
      }
      .advices:hover {
        background-size: 110%;
        @include media(min-width 320px max-width 1400px,
                12) {
          background-size: cover;
        }
      }
    }
    .right {
      .proposals {
        width: 100%;
        height: 700px;
        background: url('../img/proposals.png');
        background-size: cover;
        padding-top: 555px;
        color: white;
        padding-left: 28px;
        -webkit-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
        margin-bottom: 30px;
        transition: all .3s ease-in-out;
        background-size: 100%;
        @include media(min-width 320px max-width 720px,
                12) {
          background-size: cover;
          height: 335px;
          padding-top: 150px;
        }
        @include media(min-width 721px max-width 1400px,
                12) {
          background-size: cover;
        }
        @include media(min-width 721px max-width 960px,
                12) {
          padding-top: 504px;
        }
        h4 {
          margin: 0;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-style: italic;
          font-size: 36px;
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 18px;
          float: left;
          margin-top: 5px;
        }
        a {
          float: right;
          background-image: url('../img/button-right.png');
          width: 22px;
          height: 22px;
          margin-right: 28px;
          margin-top: 5px;
        }
      }
      .proposals:hover {
        background-size: 110%;
        @include media(min-width 320px max-width 1400px,
                12) {
          background-size: cover;
        }
      }
    }
  }
  .bottom-boxes {
    float: left;
    width: 100%;
    height: 585px;
    clear: both;
    @include media(min-width 320px max-width 720px,
            12) {
      height: auto;
    }
    .box {
      @include span-columns(4);
      @include omega();
      width: 33.3333%;
      height: 100%;
      text-align: center;
      color: white;
      padding: 0 75px;
      padding-top: 165px;
      @include media(min-width 320px max-width 720px,
              12) {
        @include span-columns(12);
        margin: 0;
        padding: 10px;
        padding-top: 75px;
      }
      @include media(min-width 721px max-width 1400px,
              12) {
        padding: 20px;
        padding-top: 50px;
      }
      h3 {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-style: italic;
        font-size: 55px;
        margin-bottom: 15px;
        @include media(min-width 320px max-width 720px,
                12) {
          font-size: 30px;
        }
        @include media(min-width 721px max-width 1400px,
                12) {
          font-size: 40px;
        }
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 21px;
        margin-bottom: 32px;
      }
      a {
        margin: auto;
        float: none;
      }
    }
    .box:first-of-type {
      background: url('../img/bottom-box1.png');
      transition: all .3s ease-in-out;
      background-size: 100%;
      @include media(min-width 320px max-width 1400px,
              12) {
        background-size: cover;
      }
    }
    .box:first-of-type:hover {
      background-size: 110%;
      @include media(min-width 320px max-width 1400px,
              12) {
        background-size: cover;
      }
    }
    .box:nth-of-type(2) {
      background: url('../img/bottom-box2.png');
      transition: all .3s ease-in-out;
      background-size: 100%;
      @include media(min-width 320px max-width 1400px,
              12) {
        background-size: cover;
      }
    }
    .box:nth-of-type(2):hover {
      background-size: 110%;
      @include media(min-width 320px max-width 1400px,
              12) {
        background-size: cover;
      }
    }
    .box:last-of-type {
      background: url('../img/bottom-box3.png');
      transition: all .3s ease-in-out;
      background-size: 100%;
      @include media(min-width 320px max-width 1400px,
              12) {
        background-size: cover;
      }
    }
    .box:last-of-type:hover {
      background-size: 110%;
      @include media(min-width 320px max-width 1400px,
              12) {
        background-size: cover;
      }
    }
  }
  &.article .boxes {
    padding:0px;
  }
}

.content-steps {
  background: #eceff1;
  -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 443px);
  min-height: -o-calc(100vh - 443px);
  /* opera */
  min-height: -webkit-calc(100vh - 443px);
  /* google, safari */
  min-height: -moz-calc(100vh - 443px);
  /* firefox */
  margin-top: 80px;
  @include media(min-width 1171px max-width 1300px,
          12) {
    padding: 10px;
  }
  @include media(min-width 320px max-width 960px,
          12) {
    margin-top: 0;
    padding-top: 50px;
  }
  &.article {
    margin-top: 0px;
    &.article-single {
      @include media(max-width 960px, 12) {
        padding-top: 0px;
      }
      @include media(min-width 1171px max-width 1300px, 12) {
        padding: 0px;
      }
      &.longterm-rent {
        section.header {
          @include media(max-width 1400px, 12) {
            height: auto;
          }
          .scroller {
            @include media(max-width 1400px, 12) {
              bottom: -45px;
            }
          }
        }
      }
    }
  }
  .main {
    float: left;
    max-width: 840px;
    width: 100%;
    position: relative;
    @include media(min-width 320px max-width 720px,
            12) {
      padding: 10px;
    }
    @include media(min-width 721px max-width 1170px,
            12) {
      padding: 20px;
      max-width: 100%;
    }
    @include media(min-width 1171px max-width 1300px,
            12) {
      max-width: 69%;
    }
    a.go-back {
      content: url('../img/h1left.png');
      @include media(min-width 1400px max-width 2500px,
              12) {
        position: relative;
        right: 65px;
      }
    }
    .title {
      @include media(min-width 320px max-width 800px,
              12) {
        float: left;
        width: 100%;
        a.go-back {
          float: left;
        }
        h1 {
          width: 80%;
          font-size: 40px;
          margin: 0;
          margin-bottom: 20px;
        }
      }
    }
    h1 {
      margin-top: 70px;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-style: italic;
      font-size: 72px;
      color: $bluegreen;
      margin-bottom: 0;
      display: inline-block;
      @include media(min-width 1400px max-width 2500px,
              12) {
        margin-left: -65px;
      }
      &:before {
        // content: url('../img/h1left.png');
        // position: absolute;
        // left: -65px;
      }
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 40px;
        text-align: center;
        margin-top: 0;
      }
      @include media(min-width 721px max-width 1170px,
              12) {
        text-align: center;
      }
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 21px;
      color: #212121;
      margin-top: 5px;
      margin-bottom: 0;
      float: left;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 16px;
        margin-top: 10px;
      }
    }
    .bike {
      background: white;
      margin-top: 30px;
      padding: 0 30px;
      color: #212121;
      float: left;
      width: 100%;
      padding-bottom: 30px;
      border: 1px solid white;
      transition: .4s;
      .top {
        margin-top: 15px;
        float: left;
        width: 100%;
        .left {
          float: left;
          width: 50%;
          @include media(min-width 320px max-width 720px,
                  12) {
            width: 100%;
            float: none;
          }
          h2 {
            margin-top: 0px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-style: italic;
            font-size: 36px;
            color: $bluegreen;
            margin-bottom: 0;
            transition: .4s;
            @include media(min-width 320px max-width 720px,
                    12) {
              text-align: center;
              font-size: 30px;
            }
          }
          p {
            font-size: 18px;
            @include media(min-width 320px max-width 720px,
                    12) {
              text-align: center;
            }
          }
        }
        .right {
          float: left;
          width: 50%;
          text-align: right;
          @include media(min-width 320px max-width 720px,
                  12) {
            width: 100%;
            float: none;
            text-align: center;
            margin-top: 10px;
          }
          h3 {
            margin-top: 0;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 36px;
            span {
              display: block;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              font-size: 13px;
            }
          }
        }
      }
      .choice {
        width: 100%;
        float: left;
        height: 40px;
        margin-top: 20px;
        .line {
          height: 1px;
          width: 100%;
          background: #eceff1;
          @include media(min-width 320px max-width 720px,
                  12) {
            display: none;
          }
        }
        a.choose {
          width: 200px;
          height: 40px;
          display: block;
          color: white;
          background: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 40px;
          text-align: center;
          float: right;
          position: relative;
          bottom: 20px;
          transition: .4s;
          @include media(min-width 320px max-width 720px,
                  12) {
            position: static;
            float: none;
            margin: auto;
          }
          &:before {
            display: inline-block;
            width: 40px;
            height: 40px;
            content: url('../img/plus.png');
            float: left;
            background: $bluegreenlight;
            line-height: 45px;
          }
        }
      }
      .data {
        .left {
          max-width: 308px;
          width: 100%;
          float: left;
          margin-right: 25px;
          @include media(min-width 320px max-width 720px,
                  12) {
            float: none;
            margin: auto;
          }
          @include media(min-width 721px max-width 960px,
                  12) {
            max-width: 25%;
            margin-right: 0;
            padding-right: 20px;
            margin-top: 10%;
          }
          @include media(min-width 1171px max-width 1300px,
                  12) {
            max-width: 262px;
          }
          a {
            outline: none;
          }
          img {
            width: 100%;
            @include media(min-width 320px max-width 720px,
                    12) {
              margin-top: 20px;
            }
          }
        }
        .right {
          max-width: 445px;
          width: 100%;
          float: left;
          @include media(min-width 320px max-width 720px,
                  12) {
            float: none;
            margin: auto;
          }
          @include media(min-width 721px max-width 960px,
                  12) {
            max-width: 75%;
          }
          .advantages {
            float: left;
            width: 100%;
            @include media(min-width 320px max-width 720px,
                    12) {
              max-width: 300px;
              margin: auto;
              float: none;
            }
            h4 {
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              font-size: 15px;
            }
            .single {
              float: left;
              width: 50%;
              height: 50px;
              margin-bottom: 10px;
              @include media(min-width 320px max-width 720px,
                      12) {
                width: 100%;
              }
              .icon {
                float: left;
                max-width: 33px;
                width: 100%;
                height: 50px;
                img {
                  max-width: 21px;
                }
              }
              .text {
                float: left;
                max-width: 189px;
                width: 100%;
                @include media(min-width 320px max-width 720px,
                        12) {
                }
                p {
                  font-family: 'Roboto', sans-serif;
                  font-weight: 300;
                  font-size: 15px;
                  padding-left: 5px;
                  margin: 0;
                  @include media(min-width 320px max-width 720px,
                          12) {
                    margin: 0;
                  }
                }
              }
            }
          }
          .technical-data {
            h4 {
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              font-size: 15px;
              margin-top: 0;
              margin-bottom: 5px;
            }
            float: left;
            margin-top: 10px;
            p {
              float: left;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              font-size: 15px;
              margin: 0;
              line-height: 30px;
              span {
                font-weight: 500;
              }
            }
            p:after {
              content: "|";
              margin: 0 12px;
              @include media(min-width 320px max-width 960px,
                      12) {
                content: "";
              }
            }
            p:nth-of-type(3):after,
            p:nth-of-type(5):after {
              content: "";
            }
            p:nth-of-type(3) {
              span {
                color: $bluegreen;
              }
              a {
                color: $bluegreen;
                font-weight: 500;
                text-decoration: none;
                img {
                  display: inline-block;
                  position: relative;
                  top: 1px;
                  left: 5px;
                }
              }
            }
          }
        }
      }
    }
    .bike:hover {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
    }
    .bike:last-of-type {
      margin-bottom: 30px;
    }
    .bike-chosen {
      border: 1px solid $bluegreen;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      .choice {
        a.chosen {
          background: #b0bec5;
          &:before {
            background: #bcc8ce;
            content: url('../img/checked.png');
            -webkit-transition: all .4s ease;
          }
          &:before:hover {
            content: url('../img/deletewhite.png');
          }
        }
      }
    }
    .bike-delete {
      border: 1px solid #df3734;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
      .top {
        .left {
          h2 {
            color: #e53935;
          }
        }
      }
      .choice {
        a.delete {
          background: #e53935;
          &:before {
            background: #e95753;
            content: url('../img/deletewhite.png');
          }
        }
      }
    }
    .bike-not-available {
      .choice {
        a.choose {
          display: none;
        }
      }
      .different-date {
        float: left;
        width: 100%;
        margin-top: 20px;
        border-top: 1px solid #eceff1;
        p {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
          margin-top: 25px;
        }
      }
      #datepicker {
        border: none;
        width: 100%;
        float: left;
        display: block;
        .ui-datepicker {
          border: none;
          width: 100% !important;
          float: left;
        }
      }
      .ll-skin-melon {
        font-size: 90%;
      }
      .ll-skin-melon .ui-widget {
        font-family: 'Roboto', sans-serif;
        background: white;
        border: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .ll-skin-melon .ui-datepicker {
        padding: 0;
      }
      .ll-skin-melon .ui-datepicker-header {
        border: none;
        background: transparent;
        font-weight: normal;
        font-size: 15px;
      }
      .ll-skin-melon .ui-datepicker-header .ui-state-hover {
        background: transparent;
        border-color: transparent;
        cursor: pointer;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-title {
        font-size: 18px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 45px;
        color: $bluegreen;
        text-align: center;
        padding-top: 0px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover,
      .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover,
      .ll-skin-melon .ui-datepicker .ui-datepicker-next,
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev {
        top: 50%;
        border: none;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover {
        left: 2px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover {
        right: 2px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-next span,
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
        background-image: url(images/ui-icons_ffffff_256x240.png);
        background-position: -32px 0;
        margin-top: 0;
        top: 0;
        font-weight: normal;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
        background-position: -96px 0;
      }
      .ll-skin-melon .ui-datepicker table {
        margin: 0;
      }
      .ll-skin-melon .ui-datepicker th {
        font-weight: normal;
        background: transparent;
        border: none;
        color: black;
        cursor: text;
        height: 25px;
        font-size: 11px;
        padding-top: 10px;
      }
      .ll-skin-melon .ui-datepicker td {
        background: rgba(255, 255, 255, 0.05);
        border: none;
        padding: 0;
      }
      .ll-skin-melon td .ui-state-default {
        background: transparent;
        border: none;
        text-align: center;
        padding: .5em;
        margin: 0;
        font-weight: normal;
        color: black;
        font-size: 13px;
        font-weight: 300;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 0.1px solid white;
      }
      .ll-skin-melon .ui-state-disabled {
        opacity: 1;
      }
      .ll-skin-melon .ui-state-disabled .ui-state-default {
        color: white;
      }
      /*.ll-skin-melon td .ui-state-active,*/
      .ll-skin-melon td .ui-state-hover {
        cursor: initial;
      }
      /****************************************/
      /****************************************/
      .ll-skin-melon td.dp-highlight .ui-state-default {
        background: #4db6ac;
        color: #FFF;
      }
      .ll-skin-melon .ui-datepicker td.dateFrom a,
      .ll-skin-melon .ui-datepicker td.dateTo a {
        background: $bluegreen;
        color: white;
      }
      .ui-datepicker .ui-datepicker-prev span,
      .ui-datepicker .ui-datepicker-next span {
        top: 120px !important;
      }
      .ui-datepicker-next {
        right: -22px !important;
        z-index: 999999 !important;
      }
      .ui-datepicker-prev {
        left: -40px !important;
      }
      .ui-datepicker-multi-3 .ui-datepicker-group {
        width: 30%;
        margin: 0 12px;
        border-right: 1px solid #eceff1;
        margin-top: 20px;
        @include media(min-width 320px max-width 720px,
                12) {
          width: 100%;
          border: none;
          margin: 0;
        }
        @include media(min-width 721px max-width 960px,
                12) {
          width: 100%;
          border: none;
          margin: 0;
          width: 33.3%;
        }
        @include media(min-width 1171px max-width 1300px,
                12) {
          border: none;
          margin: 0;
          width: 33.3%;
        }
      }
      .ui-datepicker-multi-3 .ui-datepicker-group-last {
        border-right: 0;
      }
      .legend {
        margin-top: 10px;
        float: left;
        width: 100%;
        span.icon {
          display: inline-block;
          float: left;
          width: 10px;
          height: 10px;
          background: #cfd8dc;
          margin-right: 5px;
          margin-top: 5px;
        }
        p {
          font-weight: 300;
          font-size: 11px;
        }
      }
    }
    .bike-not-available:hover {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    }
    .bike-addons {
      @include media(min-width 320px max-width 720px,
              12) {
        padding: 10px;
      }
      .image {
        float: left;
        width: 17%;
        margin-right: 4%;
        margin-top: 15px;
        @include media(min-width 320px max-width 720px,
                12) {
          width: 100%;
        }
        img {
          width: 100%;
          @include media(min-width 320px max-width 720px,
                  12) {
            max-width: 200px;
            margin: auto;
            display: block;
          }
        }
      }
      .info {
        float: left;
        width: 79%;
        @include media(min-width 320px max-width 720px,
                12) {
          width: 100%;
        }
        h3 {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 21px;
          margin: 0;
          margin-top: 35px;
          @include media(min-width 320px max-width 720px,
                  12) {
            text-align: center;
          }
        }
        h2 {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-style: italic;
          font-size: 36px;
          margin: 0;
          margin-top: 5px;
          @include media(min-width 320px max-width 720px,
                  12) {
            text-align: center;
          }
        }
      }
    }
    .addons {
      float: left;
      width: 100%;
      margin-bottom: 100px;
      h4 {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 24px;
        margin-top: 46px;
        margin-bottom: 0;
        float: left;
      }
      .addon {
        border: 1px solid #009688;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        background: white;
        margin-top: 30px;
        padding: 0 30px;
        color: #212121;
        float: left;
        width: 100%;
        padding-bottom: 30px;
        border: 1px solid white;
        transition: .4s;
        .top {
          margin-top: 15px;
          float: left;
          width: 100%;
          .left {
            float: left;
            width: 70%;
            @include media(min-width 320px max-width 720px,
                    12) {
              width: 100%;
              float: none;
            }
            h2 {
              margin-top: 0px;
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
              font-style: italic;
              font-size: 36px;
              color: $bluegreen;
              margin-bottom: 0;
              transition: .4s;
              @include media(min-width 320px max-width 720px,
                      12) {
                text-align: center;
                font-size: 30px;
              }
            }
            p {
              font-size: 18px;
              font-family: 'Roboto', sans-serif;
              font-weight: 300;
              @include media(min-width 320px max-width 720px,
                      12) {
                text-align: center;
              }
            }
          }
          .right {
            float: left;
            width: 30%;
            text-align: right;
            @include media(min-width 320px max-width 720px,
                    12) {
              width: 100%;
              float: none;
              text-align: center;
              margin-top: 10px;
            }
            h3 {
              margin-top: 0;
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
              font-size: 36px;
              span {
                display: block;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                font-size: 13px;
              }
            }
          }
        }
        .choice {
          width: 100%;
          float: left;
          height: 40px;
          .line {
            height: 1px;
            width: 100%;
            background: #eceff1;
            @include media(min-width 320px max-width 720px,
                    12) {
              display: none;
            }
          }
          a.choose {
            width: 200px;
            height: 40px;
            display: block;
            color: white;
            background: $bluegreen;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            float: right;
            position: relative;
            bottom: 20px;
            transition: .4s;
            @include media(min-width 320px max-width 720px,
                    12) {
              position: static;
              float: none;
              margin: auto;
            }
            &:before {
              display: inline-block;
              width: 40px;
              height: 40px;
              content: url('../img/plus.png');
              float: left;
              background: $bluegreenlight;
              line-height: 45px;
            }
          }
        }
        .addon-content {
          float: left;
          width: 100%;
          margin-top: 20px;
          .image {
            width: 44%;
            float: left;
            @include media(min-width 320px max-width 720px,
                    12) {
              width: 100%;
            }
            img {
              max-width: 190px;
              width: 100%;
              margin: auto;
              display: block;
            }
          }
          .description {
            width: 56%;
            float: left;
            @include media(min-width 320px max-width 720px,
                    12) {
              width: 100%;
            }
            h5 {
              float: left;
              width: 13%;
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              font-size: 15px;
              margin: 0;
              margin-top: 2px;
              @include media(min-width 320px max-width 720px,
                      12) {
                width: 100%;
                text-align: center;
                margin: 10px 0;
              }
            }
            .info {
              float: left;
              width: 87%;
              @include media(min-width 320px max-width 720px,
                      12) {
                width: 100%;
                text-align: center;
              }
              p {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                font-size: 15px;
                line-height: 25px;
              }
            }
          }
        }
      }
      .addon-chosen {
        border: 1px solid $bluegreen;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        .top {
          .left {
            p {
              font-weight: 500;
            }
          }
        }
        .choice {
          a.chosen {
            background: #b0bec5;
            &:before {
              background: #bcc8ce;
              content: url('../img/checked.png');
              -webkit-transition: all .4s ease;
            }
            &:before:hover {
              content: url('../img/deletewhite.png');
            }
          }
        }
      }
      .addon-delete {
        border: 1px solid #df3734;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        .top {
          .left {
            h2 {
              color: #e53935;
            }
          }
        }
        .choice {
          a.delete {
            background: #e53935;
            &:before {
              background: #e95753;
              content: url('../img/deletewhite.png');
            }
          }
        }
      }
    }
    .transfer-info {
      background: white;
      padding: 10px;
      margin-bottom: 50px;
      span:first-of-type {
        margin-bottom: 10px;
        margin-top: 10px;
        display: block;
        font-weight: 700;
      }
    }
  }
  section.form {
    h2 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-size: 24px;
      color: $bluegreen;
      margin-top: 20px;
      background: url('../img/arrow-up-green.png');
      background-repeat: no-repeat;
      background-position: right center;
      cursor: pointer;
      float: left;
      width: 100%;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 20px;
      }
    }
    h2.form-hide-active {
      color: #76c2bc;
      border-bottom: 1px solid #76c2bc;
      padding-bottom: 25px;
      background: url('../img/arrow-down-green-light.png');
      background-repeat: no-repeat;
      background-position: right top 10px;
    }
    h2.payments-hide-active {
      color: #76c2bc;
      border-bottom: 1px solid #76c2bc;
      padding-bottom: 25px;
      background: url('../img/arrow-down-green-light.png');
      background-repeat: no-repeat;
      background-position: right top 10px;
    }
    form {
      .form-group {
        background: white;
        margin-bottom: 15px;
        padding-top: 10px;
        float: left;
        width: 100%;
        border: 1px solid white;
        transition: .5s;
        @include media(min-width 320px max-width 1200px,
                12) {
          max-width: 900px;
        }
        label {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 13px;
          padding-left: 12px;
        }
        input {
          display: block;
          width: 100%;
          border: none;
          height: 37px;
          padding-left: 12px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
          color: $bluegreen;
          outline: none;
        }
        ::-webkit-input-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 18px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
        ::-moz-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 18px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
        :-ms-input-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 18px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
        :-moz-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 18px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
      }
      .form-group-active {
        -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid $bluegreen;
      }
      .dialling-code {
        width: 13.5%;
        padding-bottom: 9px;
        @include media(min-width 320px max-width 1200px,
                12) {
          width: 30%;
          max-width: 280px;
        }
        .select2 {
          width: 100% !important;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
        }
        .select2-container--default .select2-selection--single {
          background-position: top 10px right 10px;
          top: 0;
        }
      }
      .phone-number {
        width: 84.5%;
        margin-left: 2%;
        @include media(min-width 320px max-width 1200px,
                12) {
          width: 68%;
          max-width: 600px;
        }
      }
      .checkboxes {
        background: none;
        border: none;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
        div {
          @include media(min-width 320px max-width 1200px,
                  12) {
            margin-bottom: 10px;
          }
        }
        .styled-checkbox {
          position: absolute;
          opacity: 0;
          @include media(min-width 320px max-width 1200px,
                  12) {
            max-width: 90%;
          }
          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
          }
          & + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 25px;
            height: 25px;
            background: white;
            margin-bottom: 10px;
            position: relative;
            bottom: 3px;
          }
          &:hover + label:before {
            background: $bluegreen;
          }
          &:checked + label:before {
            background: $bluegreen;
          }
          // Disabled state label.
          &:disabled + label {
            color: $bluegreen;
            cursor: auto;
          }
          &:checked + label:after {
            content: '';
            position: absolute;
            left: 8px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
            transform: rotate(45deg);
          }
        }
        label {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 15px;
          a {
            color: $bluegreen;
            font-weight: 500;
          }
        }
      }
      .invoice-form {
        display: none;
      }
      .zip-code {
        width: 25%;
        @include media(min-width 320px max-width 1200px,
                12) {
          max-width: 900px;
          width: 100%;
        }
      }
      .city-name {
        width: 73%;
        margin-left: 2%;
        @include media(min-width 320px max-width 1200px,
                12) {
          max-width: 900px;
          width: 100%;
          margin-left: 0;
        }
      }
      .standard-button {
        height: 50px;
        font-size: 20px;
        line-height: 50px;
        width: 100%;
        clear: both;
        max-width: 395px;
        border: none;
        padding: 0;
        margin-bottom: 50px;
        &:after {
          height: 50px;
          width: 50px;
          background-image: url(../img/arrow-down.png);
          background-size: 14px;
        }
      }
    }
    .payments {
      float: left;
      width: 100%;
      .payment-button {
        width: 200px;
        height: 250px;
        display: block;
        border: 1px solid black;
        background: white;
        background-image: url('../img/FDP-baner-statyk-200x250-px.png');
        //background-size: 90%;
        background-repeat: no-repeat;
        background-position: center;
        outline: none;
        margin: 10px 0;
        cursor: pointer;
        transition: .4s;
      }
      .payment-button:hover {
        border: 1px solid $bluegreenlight;
      }
    }
    .alerts {
      float: left;
      width: 100%;
    }
  }
  &.article {
    .main {
      width: 100%;
      max-width: initial;
      h1 {
        margin-left: -5px;
        font-style: normal;
      }
      p.article-description {
        float: initial;
        font-size: 15px;
        line-height: 24px;
        margin-top: -5px;
      }
      .boxes {
        box-shadow: initial;
        padding-top: 30px;
        .center {
          width: 100%;
          .events {
            @include span-columns(4);
            @include media(max-width 960px, 12) {
              @include span-columns(12);
              background-position: center;
              padding-top:200px;
            }
            margin-bottom: 30px;
            margin-top: 0px;
            p {
              color: #ffffff;
            }
            .line {
              width:50px;
              height:1px;
              background-color: rgba(255, 255, 255, 0.6);
              margin-bottom: 10px;
              margin-left:2px;
            }
            h4 {
              font-style:normal;
              transition: all 0.3s;
              @include media(max-width 1200px, 12) {
                font-size: 30px;
              }
              @include media(max-width 1024px, 12) {
                font-size: 26px;
              }
            }
            &:hover {
              h4 {
                color: $main-color;
              }
            }
          }
        }
      }
      article {
        &.addon {
          @include span-columns(6);
          @include omega(2n);
          @include media(max-width 960px, 12) {
            @include span-columns(12);
          }
          .addon-content img {
            float: left;
            width: 55%;
            padding-right: 20px;
            padding-bottom: 11px;
            box-sizing: content-box;
            @include media(max-width 480px, 12) {
              width: 100%;
            }
          }
          .choice {
            height: 5px;
          }
          .top {
            .left {
              width: 100%;
              h2 {
                font-style: normal;
                a {
                  color: $main-color;
                }
              }
              p {
                padding-bottom: 25px;
              }
            }
          }
        }
      }
    }
  }
}

aside {
  float: left;
  max-width: 400px;
  width: 100%;
  margin-left: 30px;
  margin-top: 80px;
  background: white;
  padding: 20px 15px;
  -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  margin-bottom: 30px;
  @include media(min-width 320px max-width 720px,
          12) {
    max-width: 100%;
    margin: auto;
    margin-top: 80px;
    clear: both;
    padding: 10px;
  }
  @include media(min-width 721px max-width 1170px,
          12) {
    max-width: 100%;
    margin-left: 0;
  }
  @include media(min-width 1171px max-width 1300px,
          12) {
    max-width: 30%;
    margin-left: 1%;
  }
  input:disabled {
    background: white;
  }
  .top {
    cursor: pointer;
    h2 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-size: 21px;
      color: #212121;
      margin: 0;
      padding-bottom: 20px;
      background-image: url('../img/arrow-up-green.png');
      background-repeat: no-repeat;
      background-position: right top 8px;
      @include media(min-width 320px max-width 720px,
              12) {
        text-align: center;
      }
    }
    h2.clicked {
      background-image: url('../img/arrow-down-green.png');
      border-bottom: 1px solid #bfe5e1;
    }
  }
  .availability-check {
    font-family: 'Roboto', sans-serif;
    @include media(min-width 320px max-width 720px,
            12) {
      height: auto;
      padding: 0;
    }
    @include media(min-width 721px max-width 1450px,
            12) {
      height: 220px;
    }
    .update-dates {
      &:after {
        display: none;
      }
      border: none;
      margin-top: 5px;
      max-width: 200px;
      width: 100%;
      display: none;
      background: #009688;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding: 0;
      &:before {
        display: inline-block;
        width: 40px;
        height: 40px;
        float: left;
        line-height: 45px;
        background: #26a69a;
        content: url(../img/checked.png);
      }
    }
    .place {
      float: left;
      max-width: 565px;
      width: 100%;
      position: relative;
      @include media(min-width 320px max-width 720px,
              12) {
      }
      @include media(min-width 721px max-width 1170px,
              12) {
        max-width: 50%;
        width: 100%;
        padding-right: 40px;
      }
      input {
        margin-top: 3px;
        border: none;
        outline: none;
        height: 30px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
        width: 70%;
        @include media(min-width 320px max-width 720px,
                12) {
          width: 100%;
        }
      }
      input:hover {
        border-bottom: 1px solid $bluegreen;
        background: url('../img/edit.png');
        background-repeat: no-repeat;
        background-position: right center;
      }
      input:focus {
        border-bottom: 1px solid $bluegreen;
        background: url('../img/edit.png');
        background-repeat: no-repeat;
        background-position: right center;
      }
      p {
        transition: .4s;
      }
      ::-webkit-input-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 18px;
      }
      ::-moz-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 18px;
      }
      :-ms-input-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 18px;
      }
      :-moz-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 18px;
      }
      .receipt {
        background: white;
        float: left;
        width: 100%;
        height: 70px;
        transition: .4s;
        @include media(min-width 320px max-width 720px,
                12) {
          height: 80px;
          margin-bottom: 10px;
        }
        .left,
        .right {
          width: 47.5%;
          float: left;
          transition: .4s;
        }
        .left {
          margin-right: 3%;
        }
        p {
          margin: 0;
          color: black;
          font-weight: 300;
          font-size: 13px;
        }
        .right {
          p {
            color: $bluegreen;
            text-align: right;
            cursor: pointer;
          }
        }
      }
      .return {
        display: none;
        height: 70px;
        position: absolute;
        right: 0;
        z-index: 99999;
        background: white;
        width: 48%;
        padding-left: 10px;
        @include media(min-width 320px max-width 720px,
                12) {
          height: 80px;
        }
        p {
          margin: 0;
          color: black;
          font-weight: 300;
          font-size: 13px;
        }
        img {
          position: absolute;
          top: 5px;
          right: 5px;
          padding: 5px;
          cursor: pointer;
        }
      }
    }
    .date {
      float: left;
      background: white;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #bfe5e1;
      padding-top: 10px;
      @include media(min-width 320px max-width 720px,
              12) {
        margin: 0;
        max-width: 100%;
        margin-bottom: 10px;
      }
      @include media(min-width 721px max-width 1170px,
              12) {
        max-width: 50%;
        width: 100%;
        margin-bottom: 20px;
        padding-top: 0;
      }
      p {
        margin: 0;
        color: black;
        font-weight: 300;
        font-size: 13px;
      }
      input {
        margin-top: 3px;
        border: none;
        outline: none;
        height: 30px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 18px;
        width: 40%;
      }
      #to {
        float: right;
        text-align: right;
      }
      div.datepicker {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        float: none;
        font-family: 'Roboto', sans-serif;
        background: none \9
      ;
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#F70F141C,endColorstr=#F70F141C)";
        font-size: 10px;
        padding: 10px;
        color: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        -ms-border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        -khtml-border-radius: 5px;
        position: absolute;
        left: 46%;
        right: 0;
        width: 29%;
        margin-top: 5px;
        display: none;
        @include media(min-width 320px max-width 499px,
                12) {
          left: 0;
          width: 100%;
          z-index: 9;
        }
        @include media(min-width 500px max-width 1150px,
                12) {
          width: 50%;
          z-index: 9;
        }
        @include media(min-width 1151px max-width 1920px,
                12) {
          right: 0;
          left: 0;
          width: 100%;
          max-width: 320px;
          z-index: 9;
        }
      }
      .ui-datepicker-row-break {
        display: none;
      }
      .ui-datepicker-multi .ui-datepicker-group {
        float: left;
        padding: 5px;
      }
      .ll-skin-melon {
        font-size: 90%;
      }
      .ll-skin-melon .ui-widget {
        font-family: 'Roboto', sans-serif;
        background: white;
        border: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .ll-skin-melon .ui-datepicker {
        padding: 0;
      }
      .ll-skin-melon .ui-datepicker-header {
        border: none;
        background: transparent;
        font-weight: normal;
        font-size: 15px;
      }
      .ll-skin-melon .ui-datepicker-header .ui-state-hover {
        background: transparent;
        border-color: transparent;
        cursor: pointer;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-title {
        font-size: 18px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 45px;
        color: $bluegreen;
        text-align: center;
        padding-top: 17px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover,
      .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover,
      .ll-skin-melon .ui-datepicker .ui-datepicker-next,
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev {
        top: .9em;
        border: none;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover {
        left: 2px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover {
        right: 2px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-next span,
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
        background-image: url(images/ui-icons_ffffff_256x240.png);
        background-position: -32px 0;
        margin-top: 0;
        top: 0;
        font-weight: normal;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
        background-position: -96px 0;
      }
      .ll-skin-melon .ui-datepicker table {
        margin: 0;
      }
      .ll-skin-melon .ui-datepicker th {
        font-weight: normal;
        background: transparent;
        border: none;
        color: black;
        cursor: text;
        height: 25px;
        font-size: 11px;
        padding-top: 10px;
      }
      .ll-skin-melon .ui-datepicker td {
        background: rgba(255, 255, 255, 0.05);
        border: none;
        padding: 0;
      }
      .ll-skin-melon td .ui-state-default {
        background: transparent;
        border: none;
        text-align: center;
        padding: .5em;
        margin: 0;
        font-weight: normal;
        color: black;
        font-size: 13px;
        font-weight: 300;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 0.1px solid white;
      }
      .ll-skin-melon .ui-state-disabled {
        opacity: 1;
      }
      .ll-skin-melon .ui-state-disabled .ui-state-default {
        color: black;
      }
      /*.ll-skin-melon td .ui-state-active,*/
      .ll-skin-melon td .ui-state-hover {
        background: $bluegreen !important;
        color: white;
      }
      /****************************************/
      /****************************************/
      .ll-skin-melon td.dp-highlight .ui-state-default {
        background: #4db6ac;
        color: #FFF;
      }
      .ll-skin-melon .ui-datepicker td.dateFrom a,
      .ll-skin-melon .ui-datepicker td.dateTo a {
        background: $bluegreen;
        color: white;
      }
      .ui-datepicker td.dp-highlight:first a,
      .ui-datepicker td.dp-highlight:last a {
        background: $bluegreen;
        color: white;
      }
      .ui-datepicker.ui-datepicker-multi {
        width: auto;
        border-radius: 0px;
        border: 1px solid black;
      }
      .ui-datepicker-multi .ui-datepicker-group {
        float: left;
        width: 100%;
      }
      #datepicker {
        height: 300px;
        overflow-x: scroll;
      }
      .ui-widget {
        font-size: 100%
      }
      .dateFrom {
        position: relative;
        background: #FF9700;
        border: 4px solid #0008f5;
      }
      .ui-datepicker-next {
        right: 2px !important;
      }
      .dateFrom:after,
      .dateFrom:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      img {
        margin-left: 23px;
      }
    }
    .check {
      width: 100%;
      @include media(min-width 320px max-width 1450px,
              12) {
        margin: auto;
        margin-bottom: 20px;
        float: none;
      }
      @include media(min-width 721px max-width 1450px,
              12) {
        margin-top: 20px;
      }
      input {
        border: none;
        cursor: pointer;
      }
      #submit-form-step-three {
        line-height: 58px;
        text-align: center;
        color: white;
        text-decoration: none;
        font-size: 21px;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        background: none;
        width: -webkit-calc(100% - 58px);
        width: -moz-calc(100% - 58px);
        width: calc(100% - 58px);
        outline: none;
      }
      span {
        cursor: pointer;
        margin-top: 20px;
      }
      .standard-button {
        max-width: 370px;
        width: 100%;
        @include media(min-width 320px max-width 1170px,
                12) {
          float: right;
        }
        @include media(min-width 1171px max-width 1250px,
                12) {
          font-size: 19px;
        }
      }
    }
  }
  .item {
    float: left;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid #bfe5e1;
    margin-top: 20px;
    position: relative;
    @include media(min-width 721px max-width 1170px,
            12) {
      width: 50%;
      padding-left: 10px;
    }
    h3 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-size: 21px;
      color: $bluegreen;
      margin: 0;
      margin-bottom: 10px;
      background-image: url(../img/arrow-up-green.png);
      background-repeat: no-repeat;
      background-position: right top 8px;
      cursor: pointer;
      a {
        text-decoration: none;
        color: $bluegreen;
      }
      @include media(min-width 320px max-width 720px,
              12) {
        text-align: center;
      }
    }
    h3.clicked {
      background-image: url('../img/arrow-down-green.png');
    }
    a.remove-item {
      background: url('../img/delete-red.png');
      background-repeat: no-repeat;
      background-position: center left;
      position: absolute;
      cursor: pointer;
      left: -25px;
      width: 30px;
      height: 20px;
      display: flex;
      align-items: center;
      visibility: hidden;
      top: 50px;
      @include media(min-width 320px max-width 1170px,
              12) {
        left: 0;
      }
    }
    a.remove-item-visible {
      visibility: visible;
    }
    .item-content {
      width: 100%;
      .image {
        float: left;
        height: 50px;
        width: 80px;
        @include media(min-width 320px max-width 720px,
                12) {
          float: none;
          margin: auto;
          margin-bottom: 20px;
        }
        @include media(min-width 721px max-width 960px,
                12) {
          float: none;
          margin: auto;
          margin-bottom: 20px;
        }
        @include media(min-width 1171px max-width 1300px,
                12) {
          width: 60px;
        }
        img {
          width: 100%;
          margin-top: 5px;
        }
      }
      .item-info {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 13px;
        table {
          float: left;
          max-width: 290px;
          width: 100%;
          padding-left: 15px;
          @include media(min-width 320px max-width 720px,
                  12) {
            padding: 0;
            max-width: 400px;
            float: none;
            margin: auto;
          }
          @include media(min-width 721px max-width 960px,
                  12) {
            max-width: 100%;
            padding: 0;
          }
          @include media(min-width 1171px max-width 1300px,
                  12) {
            padding-left: 10px;
            max-width: 255px;
          }
          tr {
            td {
              span {
                font-weight: 500;
              }
            }
            td:last-of-type {
              font-weight: 500;
              text-align: right;
            }
          }
          tr:first-of-type {
            td {
              font-family: 'Ubuntu', sans-serif;
              font-size: 18px;
              font-weight: 500;
            }
            td:last-of-type {
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
            }
          }
        }
      }
      .addons {
        padding-left: 40px;
        float: left;
        @include media(min-width 320px max-width 720px,
                12) {
          padding: 0;
          max-width: 400px;
          float: none;
          margin: auto;
        }
        @include media(min-width 721px max-width 960px,
                12) {
          padding-left: 0;
        }
        @include media(min-width 1171px max-width 1300px,
                12) {
          max-width: 100%;
          padding-left: 0;
        }
        .border-one {
          width: 50px;
          height: 15px;
          border-bottom: 1px solid #eceff1;
          border-left: 1px solid #eceff1;
          display: inline-block;
          @include media(min-width 320px max-width 960px,
                  12) {
            display: none;
          }
          @include media(min-width 1171px max-width 1300px,
                  12) {
            display: none;
          }
        }
        p {
          display: inline-block;
          font-size: 13px;
          font-weight: 500;
          position: relative;
          top: 3px;
          margin: 0 5px;
        }
        .border-two {
          max-width: 217px;
          width: 100%;
          height: 15px;
          display: inline-block;
          border-bottom: 1px solid #eceff1;
          @include media(min-width 320px max-width 960px,
                  12) {
            display: none;
          }
          @include media(min-width 1171px max-width 1300px,
                  12) {
            display: none;
          }
        }
        .addon {
          margin-top: 10px;
          float: left;
          width: 100%;
          .image {
            width: 35px;
            height: 42px;
            float: left;
            margin-right: 20px;
            position: relative;
            @include media(min-width 320px max-width 720px,
                    12) {
              display: none;
            }
            img {
              position: absolute;
              margin: auto;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }
          table {
            width: 275px;
            @include media(min-width 320px max-width 720px,
                    12) {
              padding: 0;
              max-width: 400px;
              float: none;
              margin: auto;
            }
            @include media(min-width 721px max-width 960px,
                    12) {
              width: 100%;
              max-width: 387px;
            }
            @include media(min-width 1171px max-width 1300px,
                    12) {
              width: 260px;
            }
            tr:first-of-type {
              font-family: 'Ubuntu', sans-serif;
              td:first-of-type {
                color: $bluegreen;
                font-size: 15px;
              }
              td:last-of-type {
                font-weight: 700;
                text-align: right;
                font-size: 15px;
              }
            }
            tr:last-of-type {
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              td:first-of-type {
                font-weight: 300;
                font-size: 13px;
                span {
                  font-weight: 500;
                }
              }
              td:last-of-type {
                font-weight: 700;
                text-align: right;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  // .item:hover:before {
  //     content: url(../img/delete-red.png);
  //     position: absolute;
  //     cursor: pointer;
  //     left: -25px;
  //     width: 100px;
  //     height: 100px;
  //     display: flex;
  //     align-items: center;
  //     @include media(min-width 320px max-width 1170px,
  //     12) {
  //         left: 0;
  //     }
  // }
  .delivery {
    border-bottom: 1px solid #bfe5e1;
    float: left;
    width: 100%;
    margin-top: 20px;
    .left,
    .right {
      width: 50%;
      float: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 20px;
      h3 {
        margin: 0;
      }
      p {
        margin: 0;
        text-align: right;
      }
    }
  }
  .total {
    float: left;
    width: 100%;
    margin-top: 20px;
    .left,
    .right {
      width: 50%;
      float: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 21px;
      font-weight: 700;
      padding-bottom: 20px;
      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
        @include media(min-width 320px max-width 720px,
                12) {
          font-size: 12px;
        }
      }
      p {
        margin: 0;
        text-align: right;
        font-size: 16px;
        @include media(min-width 320px max-width 720px,
                12) {
          font-size: 14px;
        }
      }
    }

    .left {
      margin-top: 4px;
    }
  }
  .reservation {

    border-bottom: 1px solid #bfe5e1;
    border-top: 1px solid #bfe5e1;
    float: left;
    width: 100%;
    padding-top: 20px;
    margin-top: 0px;
    .left,
    .right {
      width: 50%;
      float: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 22px;
      font-weight: 700;
      padding-bottom: 20px;
      h3 {
        margin: 0;
      }
      p {
        font-size: 24px;
        margin: 0;
        text-align: right;
        width: 100%;
      }
    }
  }
}

/** to robił gachu! **/

td.ui-datepicker-unselectable span {
  background: #cfd8dc !important;
  color: #fff !important;
}

.bikes-item-slider {
  -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  outline: none;
  @include media(min-width 320px max-width 960px,
          12) {
    padding: 10px;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slick-slider-item {
    outline: none;
  }
  .logo {
    position: absolute;
    top: 50px;
    -webkit-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 9px 10px 41px 0px rgba(0, 0, 0, 0.2);
    width: 130px;
    height: 130px;
    @include media(min-width 320px max-width 960px,
            12) {
      position: static;
      margin: auto;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      img {
        margin: auto;
        width: 100%;
      }
    }
    @include media(min-width 961px max-width 1580px,
            12) {
      position: static;
      margin: auto;
      width: 130px;
      margin-top: 50px;
      img {
        margin: auto;
      }
    }
    img {
      width: 100%;
    }
  }
  .left {
    float: left;
    width: 50%;
    @include media(min-width 320px max-width 960px,
            12) {
      width: 100%;
    }
    h2 {
      font-family: 'Ubuntu', sans-serif;
      font-size: 72px;
      font-weight: 700;
      color: $bluegreen;
      font-style: italic;
      margin-top: 75px;
      margin-bottom: 0;
      @include media(min-width 320px max-width 960px,
              12) {
        font-size: 40px;
      }
    }
    h3 {
      margin-top: 50px;
      margin-bottom: 0;
      font-family: 'Ubuntu', sans-serif;
      font-size: 24px;
      font-weight: 700;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 21px;
      line-height: 40px;
      margin: 30px 0;
    }
    a {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 21px;
      color: $bluegreen;
      img {
        display: inline-block;
        position: relative;
        top: 1px;
        left: 5px;
      }
    }
    .bottom-bar {
      max-width: 520px;
      width: 100%;
      float: left;
      margin-top: 45px;
      @include media(min-width 320px max-width 960px,
              12) {
        max-width: 100%;
      }
      .price {
        float: left;
        background: #26a69a;
        color: white;
        width: 130px;
        height: 70px;
        text-align: center;
        @include media(min-width 320px max-width 960px,
                12) {
          float: none;
          margin: auto;
          padding-top: 5px;
          margin-bottom: 10px;
        }
        h4 {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 13px;
          margin-top: 10px;
          span {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 24px;
            display: block;
            margin-top: 20px;
          }
        }
      }
      .availability {
        float: left;
        background: $bluegreen;
        max-width: 390px;
        width: 100%;
        transition: .4s;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        @include media(min-width 320px max-width 960px,
                12) {
          float: none;
          margin: auto;
        }
        @include media(min-width 961px max-width 1400px,
                12) {
          max-width: 300px;
        }
        a {
          border: none;
          background: none;
          width: 100%;
          height: 70px;
          display: block;
          line-height: 70px;
          text-align: center;
          color: white;
          text-decoration: none;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 24px;
        }
      }
      .availability:hover {
        background: $bluegreenlight;
      }
      p.note {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 13px;
        margin-top: 10px;
        float: left;
      }
    }
  }
  .right {
    width: 50%;
    float: left;
    @include media(min-width 320px max-width 960px,
            12) {
      width: 100%;
    }
    .scroller-bottom {
      background: #009688;
      background-image: url(../img/arrow-down.png);
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      float: right;
      position: relative;
      left: 75px;
      top: 80px;
      z-index: 99;
      @include media(min-width 320px max-width 960px,
              12) {
        display: none;
      }
    }
    .image {
      float: right;
      position: fixed;
      width: 8.3%;
      margin-top: 115px;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
        position: static;
        margin-top: 20px;
      }
      img {
        float: right;
        width: 100%;
      }
    }
  }
  .gallery {
    width: 100%;
    a {
      float: left;
      width: 25%;
      filter: brightness(50%);
      transition: .4s;
      outline: none;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 50%;
      }
      img {
        width: 100%;
      }
    }
    a:hover {
      filter: brightness(100%);
    }
  }
  .technical-data {
    float: left;
    width: 100%;
    .col {
      width: 50%;
      float: left;
      margin-top: 95px;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
      }
      h2 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 72px;
        font-weight: 700;
        color: $bluegreen;
        font-style: italic;
        margin: 0;
        @include media(min-width 320px max-width 960px,
                12) {
          font-size: 40px;
        }
      }
      table {
        margin-top: 40px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 18px;
        tr {
          td {
            padding-bottom: 12px;
          }
          td:last-of-type {
            padding-left: 60px;
          }
        }
        tr.expand {
          color: $bluegreen;
          cursor: pointer;
          img {
            display: inline-block;
            width: 15px;
            position: relative;
            bottom: 2px;
          }
        }
        tr.expanded {
          display: none;
        }
      }
    }
    .col:last-of-type {
      img {
        margin: auto;
        display: block;
        max-width: 508px;
        width: 100%;
      }
    }
  }
}

.availability-wrapper {
  height: 590px;
  float: left;
  width: 100%;
  background: url('../img/availability.png');
  background-size: cover;
  @include media(min-width 320px max-width 960px,
          12) {
    height: auto;
    padding: 10px;
  }
  @include media(min-width 961px max-width 1400px,
          12) {
    padding: 10px;
    height: auto;
  }
  h2 {
    font-family: 'Ubuntu', sans-serif;
    font-size: 72px;
    font-weight: 700;
    color: $bluegreen;
    font-style: italic;
    margin-top: 185px;
    margin-bottom: 0;
    @include media(min-width 320px max-width 960px,
            12) {
      margin-top: 20px;
      font-size: 40px;
    }
  }
  p {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 21px;
  }
  .availability-check {
    background: rgba(255, 255, 255, 0.4);
    padding: 15px;
    font-family: 'Roboto', sans-serif;
    height: 100px;
    @include media(min-width 320px max-width 720px,
            12) {
      height: auto;
      padding: 0;
    }
    @include media(min-width 721px max-width 1450px,
            12) {
      height: 220px;
    }
    .place {
      float: left;
      max-width: 565px;
      width: 100%;
      position: relative;
      @include media(min-width 320px max-width 720px,
              12) {
        max-width: 100%;
      }
      @include media(min-width 721px max-width 1450px,
              12) {
        max-width: 100%;
        width: 60%;
      }
      input {
        margin-top: 3px;
        border: none;
        outline: none;
        height: 30px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
        width: 70%;
        @include media(min-width 320px max-width 720px,
                12) {
          width: 100%;
        }
      }
      p {
        transition: .4s;
      }
      ::-webkit-input-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
      }
      ::-moz-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
      }
      :-ms-input-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
      }
      :-moz-placeholder {
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
      }
      .receipt {
        background: white;
        float: left;
        width: 100%;
        height: 70px;
        transition: .4s;
        @include media(min-width 320px max-width 720px,
                12) {
          height: 80px;
          margin-bottom: 10px;
        }
        .left,
        .right {
          width: 47.5%;
          float: left;
          padding-left: 10px;
          padding-top: 10px;
          transition: .4s;
        }
        .left {
          margin-right: 3%;
        }
        p {
          margin: 0;
          color: black;
          font-weight: 300;
          font-size: 13px;
          line-height: normal;
        }
        .right {
          p {
            color: $bluegreen;
            text-align: right;
            cursor: pointer;
          }
        }
      }
      .return {
        display: none;
        height: 70px;
        position: absolute;
        right: 0;
        z-index: 99999;
        background: white;
        width: 48%;
        padding-top: 10px;
        padding-left: 10px;
        @include media(min-width 320px max-width 720px,
                12) {
          height: 80px;
        }
        p {
          margin: 0;
          color: black;
          font-weight: 300;
          font-size: 13px;
        }
        img {
          position: absolute;
          top: 5px;
          right: 5px;
          padding: 5px;
          cursor: pointer;
        }
      }
    }
    .date {
      float: left;
      background: white;
      max-width: 345px;
      width: 100%;
      height: 70px;
      margin-left: 15px;
      padding-left: 10px;
      padding-top: 10px;
      @include media(min-width 320px max-width 720px,
              12) {
        margin: 0;
        max-width: 100%;
        margin-bottom: 10px;
      }
      @include media(min-width 721px max-width 1450px,
              12) {
        max-width: 100%;
        width: 37%;
        margin-bottom: 20px;
      }
      p {
        margin: 0;
        color: black;
        font-weight: 300;
        font-size: 13px;
      }
      input {
        margin-top: 3px;
        border: none;
        outline: none;
        height: 30px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 21px;
        width: 40%;
      }
      input:disabled {
        background: white;
      }
      #to {
        float: right;
      }
      div.datepicker {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        float: none;
        font-family: 'Roboto', sans-serif;
        background: none \9
      ;
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#F70F141C,endColorstr=#F70F141C)";
        font-size: 10px;
        padding: 10px;
        color: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        -ms-border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        -khtml-border-radius: 5px;
        position: absolute;
        width: 29%;
        margin-top: 5px;
        display: none;
        @include media(min-width 320px max-width 1150px,
                12) {
          left: 0;
          width: 100%;
          z-index: 9;
        }
        @include media(min-width 1151px max-width 1920px,
                12) {
          left: 47.4%;
          width: 100%;
          max-width: 547px;
          z-index: 9;
        }
      }
      .ui-datepicker-row-break {
        display: none;
      }
      .ui-datepicker-multi .ui-datepicker-group {
        float: left;
        padding: 5px;
      }
      .ll-skin-melon {
        font-size: 90%;
      }
      .ll-skin-melon .ui-widget {
        font-family: 'Roboto', sans-serif;
        background: white;
        border: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .ll-skin-melon .ui-datepicker {
        padding: 0;
      }
      .ll-skin-melon .ui-datepicker-header {
        border: none;
        background: transparent;
        font-weight: normal;
        font-size: 15px;
      }
      .ll-skin-melon .ui-datepicker-header .ui-state-hover {
        background: transparent;
        border-color: transparent;
        cursor: pointer;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-title {
        font-size: 19px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 45px;
        color: $bluegreen;
        text-align: center;
        padding-top: 17px;
        text-transform: uppercase;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover,
      .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover,
      .ll-skin-melon .ui-datepicker .ui-datepicker-next,
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev {
        top: .9em;
        border: none;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover {
        left: 2px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover {
        right: 2px;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-next span,
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
        background-image: url(images/ui-icons_ffffff_256x240.png);
        background-position: -32px 0;
        margin-top: 0;
        top: 0;
        font-weight: normal;
      }
      .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
        background-position: -96px 0;
      }
      .ll-skin-melon .ui-datepicker table {
        margin: 0;
      }
      .ll-skin-melon .ui-datepicker th {
        font-weight: normal;
        background: transparent;
        border: none;
        color: black;
        cursor: text;
        height: 25px;
        text-transform: uppercase;
        font-size: 12px;
        padding-top: 10px;
      }
      .ll-skin-melon .ui-datepicker td {
        background: rgba(255, 255, 255, 0.05);
        border: none;
        padding: 0;
      }
      .ll-skin-melon td .ui-state-default {
        background: transparent;
        border: none;
        text-align: center;
        padding: .5em;
        margin: 0;
        font-weight: normal;
        color: black;
        font-size: 16px;
        font-weight: 300;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 0.1px solid white;
      }
      .ll-skin-melon .ui-state-disabled {
        opacity: 1;
      }
      .ll-skin-melon .ui-state-disabled .ui-state-default {
        color: black;
      }
      /*.ll-skin-melon td .ui-state-active,*/
      .ll-skin-melon td .ui-state-hover {
        background: $bluegreen !important;
        color: white;
      }
      /****************************************/
      /****************************************/
      .ll-skin-melon td.dp-highlight .ui-state-default {
        background: #4db6ac;
        color: #FFF;
      }
      .ll-skin-melon .ui-datepicker td.dateFrom a,
      .ll-skin-melon .ui-datepicker td.dateTo a {
        background: $bluegreen;
        color: white;
      }
      .ui-datepicker td.dp-highlight:first a,
      .ui-datepicker td.dp-highlight:last a {
        background: $bluegreen;
        color: white;
      }
      .ui-datepicker.ui-datepicker-multi {
        width: auto;
        border-radius: 0px;
        border: 1px solid black;
      }
      .ui-datepicker-multi .ui-datepicker-group {
        float: left;
      }
      .ui-datepicker-group:first-of-type {
        border-right: 1px solid black;
        @include media(min-width 320px max-width 720px,
                12) {
          border: none;
        }
      }
      #datepicker {
        height: 300px;
        overflow-x: scroll;
      }
      .ui-widget {
        font-size: 100%
      }
      .dateFrom {
        position: relative;
        background: #FF9700;
        border: 4px solid #0008f5;
      }
      .dateFrom:after,
      .dateFrom:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      img {
        margin-left: 13px;
        display: inline-block;
      }
    }
    .check {
      float: right;
      max-width: 300px;
      width: 100%;
      @include media(min-width 320px max-width 1450px,
              12) {
        margin: auto;
        margin-bottom: 20px;
        float: none;
      }
      @include media(min-width 721px max-width 1450px,
              12) {
        margin-top: 20px;
      }
      input {
        float: right;
        height: 70px;
        width: 100%;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 24px;
        color: white;
        background: $bluegreen;
        border: none;
        cursor: pointer;
        transition: .4s;
      }
      input:hover {
        background: $bluegreenlight;
      }
    }
  }
  .terms {
    @include media(min-width 320px max-width 960px,
            12) {
      float: left;
      width: 100%;
    }
    @include media(min-width 721px max-width 1450px,
            12) {
      text-align: center;
      width: 100%;
      float: left;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 13px;
      a {
        font-weight: 500;
        color: white;
        text-decoration: none;
      }
    }
  }
}

.static-page {
  width: 100%;
  margin: 50px 0;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.2);
  h2 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 72px;
    color: #009688;
    margin: 40px 0;
    @include media(min-width 320px max-width 960px,
            12) {
      font-size: 45px;
    }
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 21px;
    color: #212121;
    line-height: 40px;
  }
  img {
    width: 100%;
  }
  h3 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 36px;
  }
  ul {
    padding: 0;
    li {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 21px;
      padding: 5px 0;
    }
  }
  ol {
    padding-left: 0px;
    li {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 21px;
      padding: 5px 0;
    }
  }
  a {
    color: #009688;
    font-weight: 500;
    font-size: 21px;
    font-family: 'Roboto', sans-serif;
  }
}

.content-steps .main .bike-not-available .ll-skin-melon .ui-datepicker {
  float: left;
}

.popup-more {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  background: rgba(38, 50, 56, 0.8);
  width: 100%;
  height: 100%;
  float: left;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  z-index: 999999999;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  .close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $bluegreen;
    background-image: url(../img/popup-close.png);
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: 0px;
    top: -20px;
    z-index: 99999999999;
    cursor: pointer;
  }
  .bikes-item-slider-wrapper {
    -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    .bikes-item-slider {
      -webkit-box-shadow: inset 0 0px 0px -0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 0 0px 0px -0px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0px 0px -0px rgba(0, 0, 0, 0.2);
    }
  }
  .outer-wrapper {
    max-width: 1540px;
    width: 100%;
    margin: auto;
    position: relative;
  }
  .wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    /* Firefox */
    max-height: -moz-calc(100vh - 100px);
    /* WebKit */
    max-height: -webkit-calc(100vh - 100px);
    /* Opera */
    max-height: -o-calc(100vh - 100px);
    /* Standard */
    max-height: calc(100vh - 100px);
    position: relative;
    width: 100%;
    max-width: 1500px;
    height: auto;
    background: white;
    margin: auto;
    margin-top: 50px;
    .container {
      max-width: 1200px;
    }
    .bikes-thumb-slider {
      padding: 0;
      margin: 0;
      padding-bottom: 25px;
      max-width: 1485px;
      width: 100%;
      margin: auto;
      .slick-current:after {
        width: 0;
        height: 0;
        content: "";
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 13px solid white;
        display: block;
        margin: auto;
        position: relative;
        top: 45px;
        z-index: 9999;
        margin-top: -20px;
        @include media(min-width 320px max-width 720px,
                12) {
          display: none;
        }
      }
      .slick-slider-item {
        float: left;
        text-align: center;
        -webkit-transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        cursor: pointer;
        outline: none;
        @include media(min-width 320px max-width 720px,
                12) {
          margin: 20px 0;
        }
        p {
          font-size: 21px;
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-style: italic;
          margin: 0;
          @include media(min-width 320px max-width 740px,
                  12) {
            font-size: 20px;
          }
        }
        img {
          margin: auto;
          max-width: 190px;
          width: 100%;
        }
      }
      .slick-list {
        overflow: initial;
      }
      .slick-current {
        p {
          color: #212121;
        }
      }
    }
    .bikes-item-slider {
      .left {
        padding-left: 20px;
        padding-right: 50px;
        @include media(min-width 320px max-width 740px,
                12) {
          padding: 0;
        }
        p {
          padding-right: 15px;
          @include media(min-width 320px max-width 740px,
                  12) {
            padding: 0;
          }
        }
        h2 {
          @include media(min-width 320px max-width 740px,
                  12) {
            text-align: center;
          }
        }
        .bottom-bar {
          margin: 0;
          p.note {
            span {
              font-weight: 500;
            }
            span:first-of-type:after {
              content: '';
              width: 2px;
              height: 12px;
              display: inline-block;
              background: #c4c4c4;
              margin-left: 5px;
              margin-right: 3px;
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }
  .wrapper::-webkit-scrollbar {
    background-color: #EEE;
    width: 10px;
  }
  .wrapper::-webkit-scrollbar-thumb {
    border: 1px #EEE solid;
    border-radius: 2px;
    background: #777;
    -webkit-box-shadow: 0 0 8px #555 inset;
    box-shadow: 0 0 8px #555 inset;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  .wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: 0 0 0px #ccc;
    box-shadow: 0 0 0px #ccc;
  }
}

.popup-more-visible {
  visibility: visible;
  opacity: 1;
}

.thank-you-page {
  .main {
    h1 {
      margin-left: 0;
      line-height: 72px;
      @include media(min-width 320px max-width 740px,
              12) {
        margin-left: auto;
        line-height: 50px;
        display: block;
        float: left;
        text-align: left;
      }
      @include media(min-width 741px max-width 1170px,
              12) {
        margin-left: auto;
        display: block;
        float: left;
        text-align: left;
      }
    }
    p {
      margin-top: 20px;
    }
  }
  section.form {
    h2 {
      background: none;
      cursor: initial;
      color: $bluegreenlight;
      margin-top: 36px;
    }
    form {
      .form-row-1 {
        float: left;
        width: 100%;
        .form-group {
          width: 13%;
          margin-right: 2%;
          @include media(min-width 320px max-width 740px,
                  12) {
            width: 23%;
          }
        }
        .form-group:first-of-type {
          width: 70%;
          @include media(min-width 320px max-width 740px,
                  12) {
            width: 50%;
          }
        }
        .form-group:last-of-type {
          margin-right: 0;
        }
        ::-webkit-input-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 15px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
        ::-moz-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 15px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
        :-ms-input-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 15px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
        :-moz-placeholder {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 15px;
          color: #9e9e9e;
          position: relative;
          bottom: 3px;
        }
      }
      .form-row-2 {
        float: left;
        width: 100%;
        .form-group:first-of-type {
          width: 17%;
          margin-right: 2%;
          @include media(min-width 320px max-width 740px,
                  12) {
            width: 32%;
          }
        }
        .form-group:last-of-type {
          width: 81%;
          @include media(min-width 320px max-width 740px,
                  12) {
            width: 66%;
          }
          ::-webkit-input-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 15px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
          }
          ::-moz-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 15px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
          }
          :-ms-input-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 15px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
          }
          :-moz-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 15px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
          }
        }
      }
      .form-return {
        float: left;
        display: none;
      }
      div {
        float: left;
      }
      span.form-toggle {
        color: $bluegreen;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 13px;
        display: block;
        margin-top: 15px;
        margin-bottom: 35px;
        cursor: pointer;
        float: left;
      }
      .hour-receipt {
        width: 100%;
        h3 {
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 18px;
        }
        .hour {
          width: 90px;
          padding-top: 10px;
          height: 70px;
          .select2 {
            width: 100% !important;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 21px;
          }
          .select2-container--default .select2-selection--single {
            background-position: top 10px right 10px;
            top: 0;
            padding-left: 15px;
          }
        }
        .divider {
          width: 30px;
          height: 70px;
          float: left;
          text-align: center;
          line-height: 70px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
          color: #b0bec5;
        }
      }
      .standard-button {
        height: 60px;
        max-width: 370px;
        cursor: pointer;
      }
      .standard-button:after {
        display: none;
      }
    }
    .line {
      width: 100%;
      float: left;
      height: 1px;
      background: #bfe5e1;
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }
}

.longterm-rent {
  section.header {
    height: 800px;
    float: left;
    width: 100%;
    background: url('../img/header-longterm.png');
    background-size: cover;
    padding-top: 370px;
    background-attachment: fixed;
    @include clearfix();
    @include media(min-width 961px max-width 1400px,
            12) {
      height: auto;
      padding: 20px;
      padding-top: 150px;
    }
    @include media(min-width 320px max-width 960px,
            12) {
      height: auto;
      padding: 20px;
      background-attachment: inherit;
    }
    .container {
      position: relative;
    }
    .left {
      float: left;
      width: 68%;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
        text-align: center;
      }
      h2 {
        font-size: 90px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        margin: 0;
        line-height: 90px;
        @include media(min-width 320px max-width 960px,
                12) {
          font-size: 40px;
          line-height: normal;
        }
      }
      p {
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        margin-top: 15px;
      }
    }
    .right {
      float: left;
      width: 32%;
      background: rgba(255, 255, 255, 0.4);
      padding: 15px;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
      }
      table {
        color: white;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        width: 100%;
        tr {
          td:first-of-type {
            font-size: 18px;
          }
          td:last-of-type {
            font-size: 30px;
            text-align: right;
            padding: 8px 0;
            span {
              font-weight: 500;
              font-size: 60px;
              display: inline-block;
            }
            span:last-of-type {
              font-size: 30px;
              position: relative;
              bottom: 10px;
            }
          }
        }
      }
      .underline {
        float: left;
        width: 100%;
        height: 1px;
        background: #bfe5e1;
        margin-top: 10px;
      }
      .standard-button {
        width: 100%;
        max-width: 100%;
      }
      .standard-button:after {
        display: none;
      }
    }
    .scroller {
      position: absolute;
      right: -60px;
      bottom: -130px;
      background: #009688;
      background-image: url(../img/arrow-down.png);
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      cursor: pointer;
      @include media(min-width 961px max-width 1400px,
              12) {

        right: 0;
        bottom: -40px;

      }
      @include media(min-width 320px max-width 960px,
              12) {
        display: none;
      }
    }
  }
  section.advantages {
    float: left;
    width: 100%;
    @include media(min-width 961px max-width 1400px,
            12) {

      padding: 20px;

    }
    @include media(min-width 320px max-width 960px,
            12) {
      text-align: center;
    }
    h2 {
      font-size: 72px;
      color: $bluegreen;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-style: italic;
      margin-top: 95px;
      margin-bottom: 0;
      @include media(min-width 320px max-width 960px,
              12) {
        font-size: 40px;
        margin-top: 20px;
      }
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 21px;
      margin-bottom: 50px;
    }
    .single {
      float: left;
      width: 25%;
      text-align: center;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
      }
      h3 {
        font-size: 24px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        margin: 0;
        padding: 0 38px;
        font-style: italic;
      }
      p {
        font-size: 18px;
        line-height: 32px;
        padding: 0 20px;
      }
    }
  }
  section.services {
    float: left;
    width: 100%;
    -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    background: #eceff1;
    padding-bottom: 80px;
    @include media(min-width 961px max-width 1400px,
            12) {

      padding: 20px;

    }
    @include media(min-width 320px max-width 960px,
            12) {
      padding: 20px;
    }
    h2 {
      font-size: 72px;
      color: $bluegreen;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-style: italic;
      margin-top: 95px;
      margin-bottom: 0;
      @include media(min-width 320px max-width 960px,
              12) {
        font-size: 40px;
        margin-top: 20px;
        text-align: center;
      }
    }
    .col {
      float: left;
      width: 50%;
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
      }
      h4 {
        font-size: 24px;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 300;
        margin-top: 45px;
        span {
          font-weight: 700;
        }
      }
      .single {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        img {
          float: left;
          margin-right: 15px;
        }
        p {
          margin: 0;
          margin-top: 2px;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 18px;

          @include media(min-width 320px max-width 960px,
                  12) {
            font-size: 16px;
          }
        }
      }
    }
  }
  section.contact {
    float: left;
    width: 100%;
    .col {
      float: left;
      width: 50%;
      @include media(min-width 961px max-width 1400px,
              12) {

        padding: 20px;

      }
      @include media(min-width 320px max-width 960px,
              12) {
        width: 100%;
        padding: 20px;
      }
      h2 {
        font-size: 72px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-style: italic;
        margin-top: 95px;
        margin-bottom: 0;
        @include media(min-width 320px max-width 960px,
                12) {
          font-size: 40px;
          margin-top: 20px;
          text-align: center;
        }
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 21px;
        padding-right: 10px;
        padding-right: 130px;
        @include media(min-width 320px max-width 960px,
                12) {
          padding: 0;
        }
      }
      h3 {
        margin-bottom: 5px;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 24px;
      }
      a {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 18px;
        color: black;
      }
      .form-group {
        width: 100%;
        float: left;
        border: 1px solid $mint;
        padding: 10px;
        margin-bottom: 15px;
        transition: .5s;

        label {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          font-size: 13px;
          display: block;
        }
        input,
        textarea {
          border: none;
          width: 100%;
          outline: none;
          padding-top: 5px;
          padding-bottom: 5px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          color: $bluegreen;
          font-size: 21px;
          resize: none;

        }
        textarea {
          height: 100px;
        }
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          color: #9e9e9e;
          font-size: 18px;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          color: #9e9e9e;
          font-size: 18px;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          color: #9e9e9e;
          font-size: 18px;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          color: #9e9e9e;
          font-size: 18px;
        }
      }
      .form-group-active {
        border: 1px solid $bluegreen;
      }
      .form-group:first-of-type {
        margin-top: 196px;
        @include media(min-width 320px max-width 960px,
                12) {
          margin-top: 20px;
        }
      }
      .standard-button {
        float: right;
        max-width: 310px;
        margin-bottom: 100px;
        cursor: pointer;
        input {
          color: white;
          text-decoration: none;
          font-size: 21px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          background: none;
          border: none;
          outline: none;
          width: -webkit-calc(100% - 58px);
          width: -moz-calc(100% - 58px);
          width: calc(100% - 58px);
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
}
.article.longterm-rent {
  .header {
    height:710px;
  }
}


.content-blog {
  font-family: 'Ubuntu', sans-serif;
  clear:both;
  padding-top:75px;
  @include media(max-width 960px, 12) {
    padding-top: 1px;
  }

  h1 {
    font-size: 72px;
    font-weight: bold;
    color: #009688;
    @include media(max-width 960px, 12) {
      font-size:48px;
    }
  }
  h4 {
    font-size:24px;
  }
  h5 {
    font-size:18px;
  }
  p {
    font-size:18px;
    font-weight: 300;
    line-height: 30px;
  }
  blockquote {
    margin-left: 30px;
    padding-top:20px;
    position: relative;
    &:before {
      content: '';
      display: block;
      height:20px;
      width:30px;
      position: absolute;
      top:0px;
      left:-12px;
      background-image: url('../img/quote.png');
    }
    h3 {
      font-size: 21px;
      font-weight: 400;
      font-style: italic;
      color: $main-color;
      margin-bottom: 10px;
      margin-top: 15px;
    }
    span {
      font-size: 13px;
    }
  }
  .gallery {
    .item {
      width: 25%;
      float:left;
      margin-bottom: -3px;
      @include media(max-width 960px, 12) {
        width: 50%;
      }
      @include media(max-width 480px, 12) {
        width: 100%;
      }
    }
    img {
      width:100%;
    }
  }
}
.smillar {
  background-color: #eceff1;
}

.clearfix {
  clear: both;
}


.contact {

  .page-header {
    margin-top: -40px;
    height: 550px;
    width: 100%;
    background: url('../img/how-we-work.png');
    background-size: cover;
    background-position: center;
    font-style: italic;
    .container {
      padding-top: 40px;
    }
    @include media(min-width 320px max-width 1400px,
            12) {
      height: auto;
      padding: 10px;
    }
    h2 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-style: italic;
      font-size: 72px;
      color: #009688;
      margin: 40px 0;
      margin-top: 60px;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 40px;
        text-align: center;
      }
      @include media(min-width 721px max-width 960px,
              12) {
        text-align: center;
      }
    }

    p {
      font-style: initial;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 20px;
      margin-top: 10px;
      width: 50%;
      @include media(min-width 320px max-width 720px,
              12) {
        text-align: center;
        width: 100%;
      }
      @include media(min-width 721px max-width 960px,
              12) {
        text-align: center;
        width: 100%;
      }
    }
  }

  .contact-content {
    @include media(max-width 1280px,
            12) {
      padding: 0 30px;
    }
    margin-bottom: 40px;
    width: 100%;
    .left-col, .right-col {

      margin-bottom: 30px;
      @include span-columns(6);
      @include media(max-width 960px,
              12) {
        @include span-columns(12);
      }
    }

    h2 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-style: italic;
      font-size: 72px;
      color: #009688;
      margin: 40px 0;
      margin-top: 30px;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 40px;
        text-align: center;
      }
      @include media(min-width 721px max-width 960px,
              12) {
        text-align: center;
      }
    }
    .left-col {
      >p {
        font-style: initial;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 20px;
        margin-top: 10px;
      }


      .list {

        .item {
          @include span-columns(12);
          .icon {
            @include span-columns(2);

            text-align: right;
            padding: 20px 4px;

          }
          .content {
            @include span-columns(10);

            h4 {
              color: #000;
              font-size: 26px;
              font-family: 'Roboto', sans-serif;
              margin: 18px 0;
            }
            a {
              font-family: 'Roboto', sans-serif;
              width: 100%;
              color: #555;
              font-size: 18px;
              @include span-columns(12);
              margin: 0 10px;
            }

          }
        }
      }
    }

    .right-col {

      form {
        .form-group {
          background: white;
          margin-bottom: 15px;
          padding-top: 10px;
          float: left;
          width: 100%;
          border: 1px solid $bluegreen;

          transition: .5s;
          @include media(min-width 320px max-width 1200px,
                  12) {
            max-width: 900px;
          }
          label {
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            font-size: 13px;
            padding-left: 12px;
          }
          input {
            display: block;
            width: 100%;
            border: none;
            height: 37px;
            padding-left: 12px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 21px;
            color: $bluegreen;
            outline: none;
          }
          ::-webkit-input-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 18px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
            padding-left: 10px;
          }
          ::-moz-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 18px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
            padding-left: 10px;
          }
          :-ms-input-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 18px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
            padding-left: 10px;
          }
          :-moz-placeholder {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 300;
            font-size: 18px;
            color: #9e9e9e;
            position: relative;
            bottom: 3px;
            padding-left: 10px;
          }
          textarea {
            border: none;
            width: 100%;
            outline: none;
            padding: 10px;

            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            color: $bluegreen;
            font-size: 21px;
            resize: none;
          }
        }
        button {
          float: right;
          border: none;
          padding:0;

        }
      }

    }
  }
}
