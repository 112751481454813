// COLOURS
$white: #ffffff;
$black: #000000;

$lightblue: #53c4be;
$bluegreen: #009688;
$bluegreenlight: #42B2A6;

$grey: #263238;
$mint: #bfe5e1;

$main-color: #009688;
