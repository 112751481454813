body {
    direction: ltr !important;
    margin: 0 !important;
  
    
}

html {
   
}

.standard-button {
    max-width: 270px;
    width: 100%;
    height: 58px;
    background: $bluegreen;
    display: block;
    line-height: 58px;
    text-align: center;
    color: white;
    text-decoration: none;
    font-size: 21px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    float: left;
    transition: .4s;
}

.standard-button:after {
    height: 58px;
    width: 58px;
    content: "";
    background: #26a69a;
    background-image: url('../img/button-right.png');
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    float: right;
}

.standard-button:hover {
    background: $bluegreenlight;
}

.standard-button:after:hover {
    background: $bluegreen;
}

.select2-container--default .select2-selection--single {
    border: none !important;
    outline: none;
    -webkit-appearance: none;
    background-image: url('../img/select.png');
    background-repeat: no-repeat;
    background-position: top 5px right 19px;
    padding-left: 8px;
    position: relative;
    top: 5px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0 !important;
    color: $bluegreen !important;
    padding-left: 8px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none !important;
}

.select2-container .select2-choice {
    padding: 5px 10px;
    height: 40px;
    width: 132px;
    font-size: 1.2em;
}

.ui-datepicker .ui-datepicker-prev span {
    background-image: url('../img/previous.png') !important;
    background-size: 8px !important;
    background-position: center center !important;
    top: 14px !important;
}

.ui-datepicker .ui-datepicker-next span {
    background-image: url('../img/next.png') !important;
    background-size: 8px !important;
    background-position: center center !important;
    top: 14px !important;
    right: 0 !important;
}

.ui-datepicker-next {
    right: -260px !important;
    z-index: 999999 !important;
    @include media(min-width 320px max-width 544px,
    12) {
        right: 0px !important;
    }
    @include media(min-width 545px max-width 1150px,
    12) {
        right: 0px !important;
    }
}

.ui-datepicker-group {
    @include media(min-width 545px max-width 1150px,
    12) {
        width: 50%;
    }
    @include media(min-width 320px max-width 544px,
    12) {
        width: 100%;
    }
}

.select2-container {
    border-radius: 0;
    color: $bluegreen;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.select2-dropdown {
    border: 0px solid #aaa !important;
    border-radius: 0px !important;
}

.select2-results__options {
    li {
        padding-left: 10px;
    }
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $bluegreen !important;
    color: white;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $bluegreenlight !important;
    color: white;
}

.fancybox-infobar__body {
    display: none !important;
}

a {
    text-decoration: none;
}

.alert {
    text-align: center;
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 21px;
    color: white;
    float: left;
    margin: 10px 0;
    @include media(min-width 320px max-width 720px,
    12) {
        height: auto;
        padding: 10px;
    }
}

.alert-success {
    background: $bluegreenlight;
}

.alert-error {
    background: #FF6961;
}

 .fancybox-container {
    z-index: 99999999999999 !important;
}

.popup-open {
    cursor: pointer;
}