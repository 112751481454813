header.header-homepage {
  background-image: url('../img/header-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 870px;
  color: white;
  position: relative;
  background-position: bottom 0 right 0;
  @include media(min-width 320px max-width 720px,
          12) {
    padding-top: 0;
    height: auto;
    padding-bottom: 50px;
  }
  .top.sticky-top {
    li.home {
      display: inline-block;
    }
  }
  .top {
    @include outer-container;
    width: 100%;
    max-width: 1410px;
    margin: auto;
    padding-top: 30px;
    -webkit-transition: background .5s;
    transition: background .5s;
    @include media(min-width 1151px max-width 1450px,
            12) {
      padding: 10px;
    }
    li.home {
      display: none;
    }
    .container {
      padding: 0;
      max-width: 1410px;
    }
    .contact-rwd {
      display: none;
      @include media(min-width 320px max-width 720px,
              12) {
        display: block;
      }
      ul {
        margin: 0;
        text-align: center;
        padding: 0;
        li {
          padding: 10px;
          a {
            color: white;
            font-weight: 700;
            font-family: 'Ubuntu', sans-serif;
            font-style: italic;
            font-size: 15px;
          }
        }
      }
    }
    .logo {
      font-family: 'Ubuntu', sans-serif;
      font-style: italic;
      background: url('../img/logo.svg');
      content: "";
      height: 60px;
      background-repeat: no-repeat;
      @include span-columns(3);
      @include media(min-width 320px max-width 1150px,
              12) {
        max-width: 300px;
        width: 100%;
        margin: auto;
        display: block;
        float: none;

        img {

        }
      }
      a {
        color: white;
      }
      h1 {
        margin: 0;
        font-weight: 700;
        font-size: 42px;
        color: white;

        span {
          font-weight: 400;
        }
        @include media(min-width 320px max-width 1150px,
                12) {
          text-align: center;
          padding-top: 20px;
        }
      }
      h2 {
        margin: 0;
        font-weight: 300;
        font-size: 13px;
        margin-top: 5px;
        text-transform: uppercase;
        @include media(min-width 320px max-width 1150px,
                12) {
          text-align: center;
        }
        span {
          margin-left: 35px;
        }
      }
    }
    nav {
      @include span-columns(9);
      text-align: right;
      padding-top: 25px;
      font-family: 'Ubuntu', sans-serif;
      font-style: italic;
      font-size: 15px;
      @include media(min-width 721px max-width 1150px,
              12) {
        @include span-columns(12);
      }
      ul {
        text-align: right;
        display: inline-block;
        margin: 0;
        padding: 0;
        @include media(min-width 721px max-width 1150px,
                12) {
          text-align: center;
          padding: 0;
          display: block;
        }
        li {
          display: inline-block;
          padding: 0 20px;
          a {
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            transition: .3s;
          }
          a:hover {
            color: $bluegreenlight;
          }
        }
        .bold {
          border-left: 1px solid $lightblue;
          a {
            text-transform: none;
            font-weight: 700;
          }
        }
        li:nth-of-type(5) {
          a {
            font-weight: 500;
          }
        }
        li.active {
          font-weight: 700;
        }
      }
      .language-switcher {
        font-weight: 500;
        padding-left: 15px;
        padding-right: 0;
        border-left: 1px solid $lightblue;
        position: relative;
        @include media(min-width 320px max-width 720px,
                12) {
          border: none;
          padding: 0;
        }
        @include media(min-width 721px max-width 1150px,
                12) {
          border: none;
          position: relative;
          bottom: 100px;
          right: 50px;
          display: inline-block;
        }
        .dropdown-toggle {
          cursor: pointer;
          border-left: 0;
          padding-left: 15px;
        }
        .dropdown {
          position: absolute;
          text-align: center;
          display: none;
          background: white;
          padding-left: 5px;
          padding: 0;
          left: 2px;
          top: 25px;
          border: 1px solid $bluegreen;
          @include media(min-width 320px max-width 720px,
                  12) {
            width: 80%;
            padding: 0;
            top: 45px;
          }
          @include media(min-width 721px max-width 1150px,
                  12) {
            padding: 10px;
          }
          li {
            display: block;
            padding: 8px 0;
            transition: .4s;
            a {
              color: $bluegreen;
              padding: 0px 15px;
            }
          }
          li:hover {
            background: $bluegreenlight;
            color: white;
            a {
              color: white;
            }
          }
        }
      }
      @include media(min-width 320px max-width 720px,
              12) {
        width: 320px;
        height: 100vh;
        background: white;
        position: fixed;
        transform: translateX(-340px);
        transition: transform 0.6s cubic-bezier(.56, .1, .34, .91);
        padding-top: 55%;
        padding-left: 40px;
        z-index: 999999999;
        .menu-open & {
          transform: translateX(0);
        }
        ul {
          margin: 0;
          list-style: none;
          padding: 0;
          text-align: left;
          float: left;
          width: 100%;
          li {
            color: $bluegreen;
            display: block;
            padding: 10px 0;
            font-size: 20px;
            a {
              color: $bluegreen;
            }
          }
        }
      }
    }
  }
  .sticky-top {
    @include media(min-width 1410px,
            12) {
      position: fixed;
      top: 0;
      background: #009788;
      max-width: 100%;
      padding: 0;
      z-index: 999999999;
      height: 80px;
      padding: 13px 0;
      .logo {
        filter: brightness(0) invert(1);
        background: url('../img/logo1.svg');
        background-repeat: no-repeat;
        h1 {
          color: white;
          font-size: 37px;
        }
        h2 {
          font-size: 10px;
        }
      }
      nav {
        padding-top: 20px;
      }
    }
  }
  .container {
    @include outer-container;
    max-width: 1270px;
    padding-top: 215px;
    font-family: 'Ubuntu', sans-serif;
    @include media(min-width 320px max-width 720px,
            12) {
      padding: 0 20px;
    }
    @include media(min-width 721px max-width 1150px,
            12) {
      padding-top: 100px;
    }
    @include media(min-width 1151px max-width 1450px,
            12) {
      padding: 10px;
      padding-top: 100px;
    }
    h1 {
      color: $bluegreen;
      font-size: 90px;
      margin: 0;
      font-weight: 700;
      font-style: italic;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 40px;
        text-align: center;
        margin-top: 20px;
      }
      @include media(min-width 721px max-width 1150px,
              12) {
        text-align: center;
      }
    }
    h3 {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 24px;
      margin-top: 5px;
      @include media(min-width 320px max-width 720px,
              12) {
        font-size: 18px;
        text-align: center;
      }
      @include media(min-width 721px max-width 1150px,
              12) {
        text-align: center;
      }
    }
    .availability-check {
      background: rgba(255, 255, 255, 0.4);
      padding: 15px;
      font-family: 'Roboto', sans-serif;
      height: 100px;
      @include media(min-width 320px max-width 720px,
              12) {
        height: auto;
        padding: 0;
      }
      @include media(min-width 721px max-width 1450px,
              12) {
        height: 220px;
      }
      .place {
        float: left;
        max-width: 565px;
        width: 100%;
        position: relative;
        @include media(min-width 320px max-width 720px,
                12) {
          max-width: 100%;
        }
        @include media(min-width 721px max-width 1450px,
                12) {
          max-width: 100%;
          width: 60%;
        }
        input {
          margin-top: 3px;
          border: none;
          outline: none;
          height: 30px;
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
          width: 70%;
          @include media(min-width 320px max-width 720px,
                  12) {
            width: 100%;
          }
        }
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px white inset;
          -webkit-text-fill-color: #009688 !important;
        }
        p {
          transition: .4s;
        }
        ::-webkit-input-placeholder {
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
        }
        ::-moz-placeholder {
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
        }
        :-ms-input-placeholder {
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
        }
        :-moz-placeholder {
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
        }
        .receipt {
          background: white;
          float: left;
          width: 100%;
          height: 70px;
          transition: .4s;
          @include media(min-width 320px max-width 720px,
                  12) {
            height: 80px;
            margin-bottom: 10px;
          }
          .left,
          .right {
            width: 47.5%;
            float: left;
            padding-left: 10px;
            padding-top: 10px;
            transition: .4s;
          }
          .left {
            margin-right: 3%;
          }
          p {
            margin: 0;
            color: black;
            font-weight: 300;
            font-size: 13px;
          }
          .right {
            p {
              color: $bluegreen;
              text-align: right;
              cursor: pointer;
            }
          }
        }
        .return {
          display: none;
          height: 70px;
          position: absolute;
          right: 0;
          z-index: 99999;
          background: white;
          width: 48%;
          padding-top: 10px;
          padding-left: 10px;
          @include media(min-width 320px max-width 720px,
                  12) {
            height: 80px;
          }
          p {
            margin: 0;
            color: black;
            font-weight: 300;
            font-size: 13px;
          }
          img {
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 5px;
            cursor: pointer;
          }
        }
      }
      .date {
        float: left;
        background: white;
        max-width: 345px;
        width: 100%;
        height: 70px;
        margin-left: 15px;
        padding-left: 10px;
        padding-top: 10px;
        @include media(min-width 320px max-width 720px,
                12) {
          margin: 0;
          max-width: 100%;
          margin-bottom: 10px;
        }
        @include media(min-width 721px max-width 1450px,
                12) {
          max-width: 100%;
          width: 37%;
          margin-bottom: 20px;
        }
        p {
          margin: 0;
          color: black;
          font-weight: 300;
          font-size: 13px;
        }
        input {
          margin-top: 3px;
          border: none;
          outline: none;
          height: 30px;
          color: $bluegreen;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 21px;
          width: 40%;
        }
        ::-webkit-input-placeholder {
          color: #9e9e9e;
        }
        ::-moz-placeholder {
          color: #9e9e9e;
        }
        :-ms-input-placeholder {
          color: #9e9e9e;
        }
        :-moz-placeholder {
          color: #9e9e9e;
        }
        input:disabled {
          background: white;
        }
        #to {
          float: right;
        }
        div.datepicker {
          text-align: center;
          margin: 0 auto;
          width: 100%;
          float: none;
          font-family: 'Roboto', sans-serif;
          background: none \9
        ;
          -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#F70F141C,endColorstr=#F70F141C)";
          font-size: 10px;
          padding: 10px;
          color: rgba(255, 255, 255, 0.9);
          border-radius: 5px;
          -ms-border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          -khtml-border-radius: 5px;
          position: absolute;
          left: 23.7%;
          right: 0;
          width: 29%;
          margin-top: 5px;
          display: none;
          @include media(min-width 320px max-width 1150px,
                  12) {
            left: 0;
            width: 100%;
            z-index: 99999;
          }
          @include media(min-width 1151px max-width 1920px,
                  12) {
            right: 0;
            width: 100%;
            max-width: 547px;
            z-index: 9;
          }
        }
        .ui-datepicker-row-break {
          display: none;
        }
        .ui-datepicker-multi .ui-datepicker-group {
          float: left;
          padding: 5px;
          width: 50%;
          padding-left: 10px;
        }
        .ll-skin-melon {
          font-size: 90%;
        }
        .ll-skin-melon .ui-widget {
          font-family: 'Roboto', sans-serif;
          background: white;
          border: none;
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
        }
        .ll-skin-melon .ui-datepicker {
          padding: 0;
        }
        .ll-skin-melon .ui-datepicker-header {
          border: none;
          background: transparent;
          font-weight: normal;
          font-size: 15px;
          @include media(min-width 500px max-width 1150px,
                  12) {
            position: static;
          }
        }
        .ll-skin-melon .ui-datepicker-header .ui-state-hover {
          background: transparent;
          border-color: transparent;
          cursor: pointer;
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
        }
        .ll-skin-melon .ui-datepicker .ui-datepicker-title {
          font-size: 18px;
          font-weight: bold;
          padding: 0px;
          margin: 0px;
          width: 100%;
          height: 45px;
          color: $bluegreen;
          text-align: center;
          padding-top: 17px;
        }
        .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover,
        .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover,
        .ll-skin-melon .ui-datepicker .ui-datepicker-next,
        .ll-skin-melon .ui-datepicker .ui-datepicker-prev {
          top: .9em;
          border: none;
        }
        .ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover {
          left: 2px;
        }
        .ll-skin-melon .ui-datepicker .ui-datepicker-next-hover {
          right: 2px;
        }
        .ll-skin-melon .ui-datepicker .ui-datepicker-next span,
        .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
          background-image: url(images/ui-icons_ffffff_256x240.png);
          background-position: -32px 0;
          margin-top: 0;
          top: 0;
          font-weight: normal;
        }
        .ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
          background-position: -96px 0;
        }
        .ll-skin-melon .ui-datepicker table {
          margin: 0;
        }
        .ui-datepicker-prev {
          left: -8px;
          @include media(min-width 500px max-width 1150px,
                  12) {
            left: 25px !important;
            top: 30px !important;
          }
        }
        .ll-skin-melon .ui-datepicker th {
          font-weight: normal;
          background: transparent;
          border: none;
          color: black;
          cursor: text;
          height: 25px;
          font-size: 13px;
          padding-top: 10px;
        }
        .ll-skin-melon .ui-datepicker td {
          background: rgba(255, 255, 255, 0.05);
          border: none;
          padding: 0;
        }
        .ll-skin-melon td .ui-state-default {
          background: transparent;
          border: none;
          text-align: center;
          padding: .5em;
          margin: 0;
          font-weight: normal;
          color: black;
          font-size: 15px;
          font-weight: 300;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          border: 0.1px solid white;
        }
        .ll-skin-melon .ui-state-disabled {
          opacity: 1;
        }
        .ll-skin-melon .ui-state-disabled .ui-state-default {
          color: black;
        }
        /*.ll-skin-melon td .ui-state-active,*/
        .ll-skin-melon td .ui-state-hover {
          background: $bluegreen !important;
          color: white;
        }
        /****************************************/
        /****************************************/
        .ll-skin-melon td.dp-highlight .ui-state-default {
          background: #4db6ac;
          color: #FFF;
        }
        .ll-skin-melon .ui-datepicker td.dateFrom a,
        .ll-skin-melon .ui-datepicker td.dateTo a {
          background: $bluegreen;
          color: white;
        }
        .ui-datepicker td.dp-highlight:first a,
        .ui-datepicker td.dp-highlight:last a {
          background: $bluegreen;
          color: white;
        }
        .ui-datepicker.ui-datepicker-multi {
          width: auto;
          border-radius: 0px;
          border: 1px solid black;
        }
        .ui-datepicker-multi .ui-datepicker-group {
          float: left;
          @include media(min-width 320px max-width 500px,
                  12) {
            width: 100%;
          }
        }
        .ui-datepicker-group:first-of-type {
          border-right: 1px solid black;
          @include media(min-width 320px max-width 720px,
                  12) {
            border: none;
          }
        }
        .ui-datepicker .ui-datepicker-next {
          @include media(min-width 500px max-width 1150px,
                  12) {
            right: 25px !important;
            top: 30px !important;
          }
        }
        #datepicker {
          height: 300px;
          overflow-x: scroll;
        }
        .ui-widget {
          font-size: 100%
        }
        .dateFrom {
          position: relative;
          background: #FF9700;
          border: 4px solid #0008f5;
        }
        .dateFrom:after,
        .dateFrom:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        img {
          margin-left: 13px;
        }
      }
      .check {
        float: right;
        max-width: 300px;
        width: 100%;
        @include media(min-width 320px max-width 1450px,
                12) {
          margin: auto;
          margin-bottom: 20px;
          float: none;
        }
        @include media(min-width 721px max-width 1450px,
                12) {
          margin-top: 20px;
        }
        input {
          float: right;
          height: 70px;
          width: 100%;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 24px;
          color: white;
          background: $bluegreen;
          border: none;
          cursor: pointer;
          transition: .4s;
        }
        input:hover {
          background: $bluegreenlight;
        }
      }
    }
    .terms {
      @include media(min-width 320px max-width 960px,
              12) {
        float: left;
        width: 100%;
      }
      @include media(min-width 721px max-width 1450px,
              12) {
        text-align: center;
        width: 100%;
        float: left;
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 13px;
        a {
          font-weight: 500;
          color: white;
          text-decoration: none;
        }
      }
    }
    .scroller {
      background: $bluegreen;
      background-image: url('../img/arrow-down.png');
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: -30px;
      right: 120px;
      cursor: pointer;
      @include media(min-width 320px max-width 960px,
              12) {
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9;
        display: none;
      }
    }
  }
  .js-menu {
    display: none;
    @include media(min-width 320px max-width 720px,
            12) {
      display: block;
      display: none;
    }
  }
  .bar {
    &,
    &::before,
    &::after {
      transition: 0.2s background linear 0.1s, 0.2s top linear 0.2s, 0.2s transform linear;
      position: absolute;
      background: white;
      margin: auto;
      width: 30px;
      height: 2px;
      content: '';
      top: 50%;
      left: 0;
    }
    & {
      margin-top: -0.2rem
    }
    &::before {
      top: -10px;
    }
    &::after {
      top: 10px;
    }
    &::before,
    &::after {
      transform: rotate(0deg);
    }
    .active & {
      background: 0;
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
      &::before,
      &::after {
        top: 0;
      }
      &,
      &::before,
      &::after {
        transition: 0.2s background linear 0.1s, 0.2s top linear, 0.2s transform linear 0.2s;
      }
    }
  }
}

@include media(min-width 320px max-width 720px,
        12) {
  .js-menu {
    display: block;
    transition: 0.1s transform linear;
    position: relative;
    background: 0;
    float: left;
    margin: 2rem;
    height: 30px;
    width: 30px;
    z-index: 1;
    outline: 0;
    padding: 0;
    border: 0;
    z-index: 99999999999;
  }
  button.active {
    .bar,
    .bar:before,
    .bar:after {
      background: black;
    }
  }
}

header.header-step {
  height: 80px;
  background: $bluegreen;
  color: white;
  padding: 13px 0;
  font-family: 'Ubuntu', sans-serif;
  font-style: italic;
  font-weight: 700;
  // position: sticky;
  position: fixed;

  width: 100%;
  top: 0;
  z-index: 9;
  ul {
    @include media(min-width 320px max-width 960px,
            12) {
      padding: 0;
    }
  }
  @include media(min-width 320px max-width 960px,
          12) {
    height: auto;
    position: static;
  }
  @include media(min-width 961px max-width 1400px,
          12) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container {
    @include outer-container;
    width: 100%;
    max-width: 1400px;
    margin: auto;
  }
  .logo {
    font-family: 'Ubuntu', sans-serif;
    font-style: italic;
    @include span-columns(3);
    @include media(min-width 320px max-width 960px,
            12) {
      @include span-columns(12);
    }
    a {
      color: white;
    }
    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 37px;
      span {
        font-weight: 400;
      }
      @include media(min-width 320px max-width 960px,
              12) {
        text-align: center;
        padding-top: 20px;
      }

    }
    h2 {
      margin: 0;
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
      @include media(min-width 320px max-width 960px,
              12) {
        text-align: center;
      }
      span {
        margin-left: 35px;
      }
    }
    img {
      filter: brightness(0) invert(1);
      max-width: 327px;
      width: 100%;
      height: 60px;
      @include media(min-width 320px max-width 960px,
              12) {
        margin: auto;
        display: block;
        max-width: 300px;

      }
    }
  }
  .menu {
    @include span-columns(9);
    text-align: right;
    @include media(min-width 320px max-width 960px,
            12) {
      @include span-columns(12);
      text-align: center;
    }
    nav {
      display: inline-block;
      @include media(min-width 320px max-width 960px,
              12) {
        text-align: center;
        max-width: 295px;
        width: 100%;
        margin: auto;
        float: none;
        display: block;
      }
    }
    nav.steps {
      ul {
        li {
          display: inline-block;
          margin-right: 30px;
          text-transform: uppercase;
          font-weight: 400;
          transition: .4s;
          @include media(min-width 320px max-width 960px,
                  12) {
            margin: 0;
            padding: 10px;
          }
          @include media(min-width 961px max-width 1120px,
                  12) {
            margin-right: 15px;
            a {
              font-size: 14px;
            }
          }
          a {
            color: #80cbc4;
            transition: .4s;
          }
        }
        li:last-of-type {
          border-left: 1px solid $lightblue;
          margin-right: 15px;
          padding-left: 15px;
          @include media(min-width 320px max-width 960px,
                  12) {
            border: none;
            padding-left: 10px;
            margin-right: 0px;
            width: 100%;
          }
          a {
            color: white;
            font-weight: 700;
          }
        }
        li:hover {
          a {
            color: white;
          }
        }
        li.active {
          a {
            color: white;
            font-weight: 700;
          }
        }
      }
    }
  }
  .language-switcher {
    font-weight: 500;
    padding-left: 0px;
    padding-right: 0;
    position: relative;
    @include media(min-width 320px max-width 960px,
            12) {
      border: none;
      padding: 0;
    }
    ul {
      padding-left: 30px;
      border-left: 1px solid $lightblue;
      @include media(min-width 320px max-width 960px,
              12) {
        padding: 0;
        border: none;
      }
    }
    .dropdown-toggle {
      cursor: pointer;
    }
    .dropdown {
      position: absolute;
      text-align: center;
      display: none;
      background: white;
      padding-left: 15px;
      padding: 0;
      left: 9px;
      top: 45px;
      z-index: 1;
      border: 1px solid $bluegreen;
      @include media(min-width 320px max-width 960px,
              12) {
        margin: auto;
        width: 50%;
        max-width: 150px;
        padding: 0;
        top: 45px;
        left: 0;
        right: 0;
      }
      @include media(min-width 721px max-width 960px,
              12) {
        padding: 10px;
      }
      li {
        display: block;
        padding: 8px 0;
        transition: .4s;
        a {
          color: $bluegreen;
          padding: 0px 15px;
        }
      }
      li:hover {
        background: $bluegreenlight;
        color: white;
        a {
          color: white;
        }
      }
    }
  }
}

header.article {
  .logo {
    @include media(max-width 960px, 12) {
      padding-left:10px;
      padding-right:10px;
    }
    a {
      @include media(max-width 960px, 12) {
        float: left;
      }
    }
  }
  .steps {
    ul {
      li {
        &:first-child {
          border-right: 1px solid #53c4be;
          margin-right: 10px !important;
          padding-right: 20px;
          @include media(max-width 960px, 12) {
            border-right: initial;
          }
        }
        @include media(max-width 960px, 12) {
          width: 100%;
        }
      }
    }
  }
  .menu {
    @include media(max-width 960px, 12) {
      display: none;
    }
    nav.steps {
      ul {
        padding-left: 0px;
        @include media(max-width 1400px, 12) {
          margin-top: 10px;
        }
        li {
          margin-right: 20px;
          a {
            font-size: 14px;
          }
          &:last-of-type {
            border-left: initial;
            margin-right: 20px;
            padding-left: initial;
            &:hover {
              a {
                color: white;
              }
            }
            a {
              color: #80cbc4;
              font-weight: 400;
            }
          }
        }
      }
      @include media(max-width 1400px, 12) {
        width: 93%;
      }
    }
  }
  .logo {
    @include span-columns(3);
    margin-right: 0px;
    img {
      max-width: 240px;
    }
    @include media(max-width 960px, 12) {
      width: 100%;
    }
  }
  .language-switcher {
    ul {
      padding-left: 20px;
      @include media(max-width 960px, 12) {
        padding-left:0px;
      }
    }
  }
}

.submenu {
  width: 100%;
  background-color: white;
  -webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  ul {
    padding: 0px;
    margin: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    @include media(max-width 1300px, 12) {
      padding-left: 10px;
      padding-right: 10px;
    }
    li {
      display: inline-block;
      margin-right: 20px;
      position: relative;
      &.active {
        font-weight: bold;
        &:after {
          content: '';
          position: absolute;
          bottom: -26px;
          left: 0px;
          right: 0px;
          margin: 0 auto;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #ffffff;
        }
      }
      a {
        font-size: 14px;
        color: #009688;
        text-decoration: none;
        font-style: italic;
        text-transform: uppercase;
        font-family: 'Ubuntu', sans-serif;
      }
    }
  }
}

header.header-bike-more {
  height: auto;
  padding-bottom: 0;
  background: white;
  padding: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;
  @include media(min-width 320px max-width 960px,
          12) {
    position: static;
  }
  .top-wrapper {
    background: $bluegreen;
    padding-top: 13px;
    @include media(min-width 961px max-width 1400px,
            12) {
      padding: 10px;
    }
  }
  .container {
    .logo {
      font-family: 'Ubuntu', sans-serif;
      font-style: italic;
      padding-bottom: 13px;
      @include span-columns(2);
      @include media(min-width 320px max-width 960px,
              12) {
        @include span-columns(12);
      }
      @include media(min-width 961px max-width 1400px,
              12) {
        @include span-columns(3);
      }
      a {
        color: white;
      }
      h1 {
        margin: 0;
        font-weight: 700;
        font-size: 33px;
        span {
          font-weight: 400;
        }
        @include media(min-width 320px max-width 960px,
                12) {
          text-align: center;
          padding-top: 20px;
        }
      }
      h2 {
        margin: 0;
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
        @include media(min-width 320px max-width 960px,
                12) {
          text-align: center;
        }
        span {
          margin-left: 35px;
        }
      }
    }
    .menu {
      @include span-columns(10);
      text-align: right;
      @include media(min-width 320px max-width 960px,
              12) {
        @include span-columns(12);
        text-align: center;
      }
      @include media(min-width 961px max-width 1400px,
              12) {
        @include span-columns(9);
      }
      nav {
        display: inline-block;
        float: left;
        @include media(min-width 320px max-width 960px,
                12) {
          text-align: center;
          max-width: 295px;
          width: 100%;
          margin: auto;
          float: none;
          display: block;
        }
      }
      nav.steps {
        @include media(min-width 961px max-width 1400px,
                12) {
          padding: 0;
          text-align: left;
          width: 90%;
        }
        ul {
          li {
            display: inline-block;
            margin-right: 30px;
            text-transform: uppercase;
            font-weight: 400;
            transition: .4s;
            @include media(min-width 320px max-width 960px,
                    12) {
              margin: 0;
              padding: 10px;
              width: 100%;
            }
            a {
              font-size: 14px;
              color: white;
            }
          }
          li:last-of-type {
            border-left: none;
            margin-right: 30px;
            padding-left: 0px;
          }
          li:first-of-type {
            border-right: 1px solid #53c4be;
            margin-right: 15px;
            padding-right: 15px;
            @include media(min-width 320px max-width 960px,
                    12) {
              border: none;
              margin: 0;
              padding: 10px;
            }
          }
        }
      }
      .language-switcher {
        @include media(min-width 961px max-width 1400px,
                12) {
          width: 10%;
          z-index: 9999;
        }
      }
    }
  }
  .wrapper {
    width: 100%;
    background: white;
    //-webkit-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    //-moz-box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
    //box-shadow: inset 0 80px 80px -80px rgba(0, 0, 0, 0.2);
  }
  .bikes-thumb-slider {
    padding: 0;
    margin: 0;
    padding-bottom: 25px;
    max-width: 1485px;
    width: 100%;
    margin: auto;
    @include media(min-width 320px max-width 720px,
            12) {
      display: none;
    }
    .slick-slider-item {
      float: left;
      text-align: center;
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      cursor: pointer;
      outline: none;
      @include media(min-width 320px max-width 720px,
              12) {
        margin: 20px 0;
      }
      p {
        font-size: 21px;
        color: $bluegreen;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-style: italic;
        margin: 0;
        @include media(min-width 320px max-width 740px,
                12) {
          font-size: 20px;
        }
      }
      img {
        margin: auto;
        max-width: 190px;
        width: 100%;
      }
    }
    .slick-list {
      overflow: initial;
    }
    .slick-current {
      p {
        color: #212121;
      }
    }
  }
  nav.inner-navigation {
    background: white;
    color: black;
    max-width: 1270px;
    width: 100%;
    margin: auto;
    border-top: 1px solid #eceff1;
    ul {
      padding: 12px 0;
      margin: 0;
      li {
        display: inline-block;
        color: $bluegreen;
        font-weight: 400;
        margin-right: 15px;
        cursor: pointer;
        @include media(min-width 320px max-width 960px,
                12) {
          display: block;
          text-align: center;
          margin: 0;
          padding: 10px;
        }
        @include media(min-width 961px max-width 1400px,
                12) {
          padding-left: 10px;
        }
      }
      li.active {
        font-weight: 700;
      }
    }
  }
}
