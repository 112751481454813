footer {
    background: $grey;
    color: white;
    float: left;
    width: 100%;
   
    @include media(min-width 320px max-width 720px, 12) {
        padding: 10px;
    }
    @include media(min-width 721px max-width 1340px, 12) {
        padding: 20px;
    }
    .container {
        @include outer-container;
        max-width: 1270px;
        padding-top: 53px;
        &.custom {
            .textcol {
                @include media(min-width 320px max-width 720px, 12) {
                    width: 100% !important;
                    min-height: 100px;
                }
            }
        }
        .col {
            &.col-2 {
                width: 40%;
            }
            float: left;
            width: 20%;
            @include media(min-width 320px max-width 720px, 12) {
                width: 50%;
                min-height: 280px;
                text-align: center;
            }
            h4 {
                margin: 0;
                font-family: 'Ubuntu', sans-serif;
                font-weight: 700;
                font-style: italic;
                margin-bottom: 0px;
                font-size: 18px;
            }
            ul {
                padding: 0;
                margin-top: 10px;
                &.text {
                    li {
                        padding: 0;
                        line-height: 25px;
                    }
                }
                li {
                    color: white;
                    text-decoration: none;
                    font-weight: 300;
                    line-height: 33px;
                    font-size: 14px;
                    font-family: 'Roboto', sans-serif;
                    a {
                        color: white;
                        text-decoration: none;
                        font-weight: 300;
                        line-height: 33px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                    }
                }
            }
            .bold {
                a {
                    text-transform: none;
                    font-weight: 700;
                }
            }
            .select-box {
                margin-top: 15px;
                background: white;
                height: 50px;
                padding-top: 8px;
                label {
                    font-weight: 300;
                    font-family: 'Roboto', sans-serif;
                    color: #212121;
                    font-size: 12px;
                    margin-left: 3px;
                    position: relative;
                    bottom: 7px;
                    z-index: 99;
                    padding-left: 8px;
                }
                .select2 {
                    width: 100%;
                    border: none;
                    font-size: 16px;
                    font-family: 'Ubuntu', sans-serif;
                    font-weight: bold;
                    outline: none;
                    color: $bluegreen;
                    -webkit-appearance: none;
                    background-image: url('../img/select.png');
                    background-repeat: no-repeat;
                    background-position: top 10px right 19px;
                    padding-left: 3px;
                    height: 35px;
                    position: relative;
                    bottom: 15px;
                    width: 100% !important;
                    border: none;
                }
            }
            .select-box:first-of-type {
                margin-top: 20px;
            }
        }
        .settings {
            @include media(min-width 320px max-width 720px,
            12) {
                float: none;
                margin: auto;
                text-align: left;
                margin-bottom: 50px;
                h4 {
                    text-align: center;
                }
            }
        }
        .bottom-bar {
            width: 100%;
            float: left;
            border-top: 1px solid #37474f;
            .copyright {
                float: left;
                width: 50%;
                margin-top: 23px;
                @include media(min-width 320px max-width 720px,
                12) {
                    width: 100%;
                }
                p {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 300;
                    font-family: 'Roboto', sans-serif;
                    @include media(min-width 320px max-width 720px,
                    12) {
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
            }
            .socials {
                text-align: right;
                padding-right: 8px;
                margin-top: 20px;
                padding-bottom: 20px;
                @include media(min-width 320px max-width 720px,
                12) {
                    text-align: center;
                    margin-top: 20px;
                }
                a {
                    margin-right: 43px;
                }
                a:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}
