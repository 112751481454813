
.editor {
  max-width: 920px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Ubuntu", sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold !important;
    color: $bluegreen;
  }

  h1 {
    font-size: 2em !important;
    padding: .67em 0 !important;
  }

  h2 {
    font-size: 1.5em !important;
    padding: .83em 0 !important;
  }

  h3 {
    font-size: 1.17em !important;
    padding: .83em 0 !important;
  }

  h4 {
    font-size: 1em !important;
  }

  h5 {
    font-size: .83em !important;
  }

  &.fullwidth {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: #000;
    &:hover, &:active, &:visited {
      color: #000;
    }
  }

  ul {
    padding-left: 14px;
    font-size: 21px;

    li {
      list-style: none;
      font-family: "Ubuntu", sans-serif;

      &:before {
        color: $main-color;
        content: "•";
        position: relative;
        left: -7px;
        font-size: 18px;
        margin-left: -7px;
      }
    }
  }

  ol {
    padding-left: 1.5em;
    font-size: 21px;

    li {
      list-style-type: none;
      counter-increment: list;
      position: relative;

      &:after {
        content: counter(list) ".";
        position: absolute;
        left: -2.5em;
        width: 2em;
        text-align: right;
        font-weight: bold;
        color: $main-color;
      }
    }
  }
}

