// RESETS
a:hover {
    text-decoration: none;
}

li {
    list-style: none;
}

body.article {
    padding-top:80px;
    @include media(max-width 960px, 12) {
        padding-top: 0px;
    }
}

// habmurger

.hamb {
    display: none;
    @include media(max-width 960px, 12) {
        display: block;
        float: right;
    }
}

.navigation-checkbox {
    display: none;
}

.navigation-toggle {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    cursor: pointer;
}

.navigation-toggle-label {
    //font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: white;
    text-align: center;
    display: block;
    text-transform: uppercase;
    position: relative;
    bottom: -20px;
}

.navigation-toggle-icon {
    border-bottom: 6px solid white;
    padding-top: 17px;
    display: block;
}

.navigation-toggle-icon:before,
.navigation-toggle-icon:after {
    border-top: 6px solid white;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
}

.navigation-toggle-icon:before {
    top: 4px;
}

.navigation-toggle-icon:after {
    bottom: 4px;
}

/*
 * This is where all the animation happens
 */
.navigation-toggle-icon,
.navigation-toggle-icon:before,
.navigation-toggle-icon:after {
    -webkit-transition-property: -webkit-transform, border-color;
    -moz-transition-property: -moz-transform, border-color;
    transition-property: transform, border-color;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -webkit-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
    -moz-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
    transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.navigation-toggle-icon {
    -webkit-transition-delay: 0.125s;
    -moz-transition-delay: 0.125s;
    transition-delay: 0.125s;
}

.navigation-toggle-icon:before,
.navigation-toggle-icon:after {
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}

.navigation-checkbox:checked + .navigation-toggle .navigation-toggle-icon {
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
}
.navigation-checkbox:checked + .navigation-toggle .navigation-toggle-icon:before,
.navigation-checkbox:checked + .navigation-toggle .navigation-toggle-icon:after {
    -webkit-transition-delay: 0.125s;
    -moz-transition-delay: 0.125s;
    transition-delay: 0.125s;
}
.navigation-checkbox:checked + .navigation-toggle .navigation-toggle-icon:before {
    -webkit-transform: translateY(13px) rotate(135deg);
    -moz-transform: translateY(13px) rotate(135deg);
    -ms-transform: translateY(13px) rotate(135deg);
    -o-transform: translateY(13px) rotate(135deg);
    transform: translateY(13px) rotate(135deg);
}
.navigation-checkbox:checked + .navigation-toggle .navigation-toggle-icon {
    border-color: transparent;
}
.navigation-checkbox:checked + .navigation-toggle .navigation-toggle-icon:after {
    -webkit-transform: translateY(-13px) rotate(-135deg);
    -moz-transform: translateY(-13px) rotate(-135deg);
    -ms-transform: translateY(-13px) rotate(-135deg);
    -o-transform: translateY(-13px) rotate(-135deg);
    transform: translateY(-13px) rotate(-135deg);
}